import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
  ListSubheader,
  TextField, CircularProgress,

} from "@material-ui/core"
import SharedStyles from '../../../shared_styles'
import { setAppBar, setLoading, setSnackbar } from "../system/system_slice"
import { API_ADDRESS, APP_BAR_STATE, USER_SETTING_KEY, STATEMENT_CHAR_LIMIT } from "../../../../lib/constants"
import { useDispatch, useSelector } from "react-redux"
import { navigate } from "@reach/router"
import axios from "axios"
import { getAuthToken } from "../../../../lib/auth"

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: '56px',
    width: '56px'
  }
}))

const SAVE_BUTTON_LOADING_KEY = 'settingsStatementSaving';
const STATEMENT = 'statement'

const Statement = () => {
  const dispatch = useDispatch();
  const styles = SharedStyles();
  const classes = useStyles();

  const {user, loading} = useSelector(state => state.system);
  const [statement, setStatement] = useState('');

  useEffect(() => {
    dispatch(setAppBar({
      state: APP_BAR_STATE.BACK_LABEL,
      label: 'Personal Statement',
      backLocation: '/app/settings'
    }));
    setStatement(user.Statement)
  }, [])

  const handleTextField = (event) => {
    if (event.target.value.length > STATEMENT_CHAR_LIMIT) return;
    setStatement(event.target.value);
  }

  const save = () => {
    const data = {
      key: USER_SETTING_KEY.STATEMENT,
      value: statement
    }
    const config = {
      headers: { Authorization: `Bearer ${getAuthToken()}` }
    };
    dispatch(setLoading({key: SAVE_BUTTON_LOADING_KEY, value: true}));
    axios.post(`${API_ADDRESS}/user/update`, data, config)
      .then((response) => {
        if (response.data.error) {
          return alert(response.data.error);
        }

        dispatch(setSnackbar({
          open: true,
          text: 'Statement Saved'
        }))
        dispatch(setLoading({key: SAVE_BUTTON_LOADING_KEY, value: false}));
        navigate('/app/settings');
      })
      .then(()=> setLoading(false))
  }

  return (
    <Container maxWidth="sm">
      <TextField
        id={STATEMENT}
        name={STATEMENT}
        label="Personal statement"
        variant="outlined"
        fullWidth
        required
        value={statement}
        onChange={handleTextField}
        margin="dense"
        multiline
        rows={4}
        rowsMax={7}
        helperText={`${STATEMENT_CHAR_LIMIT} character or less.`}
      />
      <Button
        color="primary"
        variant="contained"
        onClick={save}
      >
        {loading[SAVE_BUTTON_LOADING_KEY]? <CircularProgress style={{color:'white', borderColor:'white'}} size={24}/> : 'Save'}
      </Button>
    </Container>
  );
}

export default Statement;
