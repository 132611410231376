import React, {useState, useEffect, useContext} from 'react';
import { MixpanelContext } from '../../lib/tracker';
import {
  Avatar,
  Box,
  Hidden,
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Container,
  makeStyles,
  Typography, Grid,
  Toolbar,
  IconButton,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  ListItemIcon,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem
} from "@material-ui/core"
import Layout from "../layout"
import HomeIcon from '@material-ui/icons/Home';
import GroupIcon from '@material-ui/icons/Group';
import FeedbackIcon from '@material-ui/icons/Feedback';
import AddBoxIcon from '@material-ui/icons/AddBox';
import MenuIcon from '@material-ui/icons/Menu';


import theme from "../../lib/theme"
import {navigate} from "@reach/router"
import {createEnum,  APP_BAR_STATE, REQUEST_STATUS } from "../../lib/constants"
import { useDispatch, useSelector } from 'react-redux';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SharedStyles from '../shared_styles'
import clsx from 'clsx';
import { setSnackbar} from "./features/system/system_slice"
import Util from '../../lib/util'

const useStyles = makeStyles({
  appBar: {
    padding: theme.spacing(0, .5),
    marginBottom: theme.spacing(2),
    background: 'white'
  },
  appBarTitle: {
    flexGrow: 1
  },
  appBarLeftButton: {
    position: 'absolute',
    left: 0,
    marginRight: theme.spacing(2),
  },
  container: {
    width: '614px'
  },
  root: {
    width: '100%',
  },

  appBody: {
    padding: theme.spacing(.5),
    marginBottom: '60px',
    paddingTop: '75px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '56px'
    },
  },
  logo: {
    width: '140px',
    maxHeight: '50px'
  }

});

const PAGE_KEY = createEnum(['HOME', 'NETWORK', 'CALL', 'SETTINGS', 'FEEDBACK'], 0);

const App = (props) => {
  const dispatch = useDispatch();
  const styles = SharedStyles();
  const classes = useStyles();
  const mixpanel = useContext(MixpanelContext);

  const {appBar, user, userRequest, networkInfo} = useSelector(state => state.system);

  //temporary - will move to redux
  const [nav, setNav] = useState(0);

  const [menuAnchor, setMenuAnchor]=useState(null);

  const [showCallAlert, setShowCallAlert] = useState(false);

  useEffect(() => {
    const slug = props['*'];
    if (slug.startsWith('network')) {
      setNav(PAGE_KEY.NETWORK);
    } else if(slug.startsWith('settings')) {
      setNav(PAGE_KEY.SETTINGS);
    } else if(slug.startsWith('request')) {
      setNav(PAGE_KEY.CALL)
    }
  }, [])

  const openMenuFromIcon = (event) => {
    setMenuAnchor(event.currentTarget)
  }

  const getButtonTextAppBar = (label) => {
    return (
      <React.Fragment>
        {appBar.state === APP_BAR_STATE.BACK_LABEL &&
          <IconButton
            edge="start"
            className={clsx([classes.appBarLeftButton, styles.centerElements])}
            aria-label="back"
            onClick={() => navigate(appBar.backLocation ? appBar.backLocation : -1)}
          >
            <ChevronLeftIcon/>
          </IconButton>
        }
        <Typography variant="h6" color="primary" className={clsx([classes.appBarTitle, styles.centerText])}>
          {appBar.label}
        </Typography>
        <Hidden xsDown>
          <IconButton
            edge="end"
            className={clsx([styles.centerElements])}
            aria-label="back"
            onClick={openMenuFromIcon}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={menuAnchor}
            keepMounted
            open={Boolean(menuAnchor)}
            onClose={()=>setMenuAnchor(null)}
          >
            <MenuItem onClick={()=>handleMenuButton(PAGE_KEY.HOME)}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </MenuItem>
            <MenuItem onClick={()=>handleMenuButton(PAGE_KEY.NETWORK)}>
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary="My Network" />
            </MenuItem>

            {!networkInfo.SkipTopics &&
              <MenuItem onClick={() => handleMenuButton(PAGE_KEY.CALL)}>
                <ListItemIcon>
                  <AddBoxIcon/>
                </ListItemIcon>
                <ListItemText primary="New Conversation"/>
              </MenuItem>
            }
            <MenuItem onClick={()=>handleMenuButton(PAGE_KEY.FEEDBACK)}>
              <ListItemIcon>
                <FeedbackIcon />
              </ListItemIcon>
              <ListItemText primary="App Feedback" />
            </MenuItem>
            <MenuItem onClick={()=>handleMenuButton(PAGE_KEY.SETTINGS)}>
              <ListItemAvatar>
                <Util.UserAvatar user={user}/>
              </ListItemAvatar>
              <ListItemText primary="Settings" />
            </MenuItem>
          </Menu>
        </Hidden>
      </React.Fragment>
    );
  }

  const getHomeAppBar = () => {
    return (
      <Grid container direction="row">
        <Box style={{display: 'flex', alignItems: 'center'}}>
          <img src={networkInfo.Logo} className={classes.logo} alt={`${networkInfo.Name} logo`}/>
        </Box>
        <Box style={{flexGrow:1}} />
        <Grid item align="right">
          <Hidden xsDown>
            <IconButton onClick={()=>handleMenuButton(PAGE_KEY.HOME)}>
              <HomeIcon />
            </IconButton>
            <IconButton onClick={()=>handleMenuButton(PAGE_KEY.NETWORK)}>
              <GroupIcon />
            </IconButton>
            {!networkInfo.SkipTopics &&
              <IconButton onClick={() => handleMenuButton(PAGE_KEY.CALL)}>
                <AddBoxIcon/>
              </IconButton>
            }
            <IconButton onClick={()=>handleMenuButton(PAGE_KEY.FEEDBACK)}>
              <FeedbackIcon />
            </IconButton>
            <IconButton onClick={()=>handleMenuButton(PAGE_KEY.SETTINGS)}>
              <Util.UserAvatar user={user} />
            </IconButton>
          </Hidden>
        </Grid>
      </Grid>
    );
  }

  const getAppBar = () => {
    switch(appBar.state) {
      case APP_BAR_STATE.HOME:
        return getHomeAppBar();
      case APP_BAR_STATE.LABEL:
      case APP_BAR_STATE.BACK_LABEL:
        return getButtonTextAppBar()
      case APP_BAR_STATE.NONE:
        return null;
      default:
        return null;
    }
  }

  const handleMenuButton = (key) => {
    mixpanel.track('Menu Item Selected',{
      'Key': key
    });
    setMenuAnchor(null);
    let path = '';
    switch(key) {
      case PAGE_KEY.HOME:
        path = '/app';
        break;
      case PAGE_KEY.NETWORK:
        path = '/app/network';
        break;
      case PAGE_KEY.SETTINGS:
        path = '/app/settings';
        break;
      case PAGE_KEY.CALL:
        if (userRequest && userRequest.Status === REQUEST_STATUS.PENDING) {
          dispatch(setSnackbar({
            open: true,
            text: 'You can only have call request out at a time'
          }));
          return false;
        }
        if (!networkInfo.ExpandNetworkEnabled && !user.ExpandNetworkEnabled) {
          setShowCallAlert(true);
          return false;
        }
        path = '/app/request/topic';
        break;
      case PAGE_KEY.FEEDBACK:
        window.open('http://bit.ly/Greenwoodx_app_feedback', '_blank');
        return false;
      default:
        return false;
    }
    navigate(path);
    return true;
  }

  return (
    <Layout showHeader={false} showFooter={false}>
      {appBar.state !== APP_BAR_STATE.NONE &&
      <AppBar position="fixed" color="transparent" elevation={0} className={classes.appBar}>
          <Container maxWidth="sm" disableGutters>
            <Toolbar variant="dense" disableGutters>
              {getAppBar()}
            </Toolbar>
          </Container>
        </AppBar>
      }
      <Container maxWidth="sm" disableGutters className={classes.appBody}>
        {props.children}
      </Container>
      <Hidden smUp>
        <BottomNavigation
          value={nav}
          onChange={(event, newValue) => {
            if(handleMenuButton(newValue)) {
              setNav(newValue);
            }
          }}
          className={styles.bottomNav}
          style={{borderTop: '1px solid lightgray', background: 'white', zIndex: 10}}
          showLabels
        >
          <BottomNavigationAction icon={<HomeIcon />} label="Home" value={PAGE_KEY.HOME} />
          <BottomNavigationAction icon={<GroupIcon />} label="My Network" value={PAGE_KEY.NETWORK} />
          {!networkInfo.SkipTopics &&
            <BottomNavigationAction icon={<AddBoxIcon/>} label="New" value={PAGE_KEY.CALL}/>
          }
          {/*<BottomNavigationAction icon={<FeedbackIcon />} label="Feedback" value={PAGE_KEY.FEEDBACK} />*/}
          <BottomNavigationAction value={PAGE_KEY.SETTINGS} icon={<Avatar style={{width:'30px',height:'30px'}} src={user.ProfilePic} alt={user.FirstName}/>}/>
        </BottomNavigation>
      </Hidden>
      <Dialog open={showCallAlert}>
        <DialogTitle>{"Hang tight."}</DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            We know how powerful being able to tap into the Greenwoodx network is and we're working hard to make sure that we have a solid network before we shift into Phase 2.
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button
            onClick={()=>setShowCallAlert(false)}
            variant="outlined"
          >
            Got it!
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}

export default App;
