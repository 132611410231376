import clsx from 'clsx';
import React, {useEffect, useState, useContext} from 'react'
import {MixpanelContext} from "../../lib/tracker"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItemText,
  ListItemAvatar,
  ListItem, ListItemSecondaryAction,
  IconButton,
  ListItemIcon,

  Avatar,
  Box,
  Button,
  Typography,
  Collapse,
  Card,
  CardHeader,
  makeStyles,
  Paper,
} from "@material-ui/core"
import {
  Alert,
  AlertTitle
} from '@material-ui/lab';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CancelIcon from '@material-ui/icons/Cancel';

import HomePageHero from "./ui/home_page_hero"
import SmallRequestModal from "./features/request/ui/small_request_modal"
import { setAppBar, setLoading, setSnackbar } from "./features/system/system_slice"
import {
  API_ADDRESS,
  APP_BAR_STATE,
  REQUEST_ACTION,
  REQUEST_ORIGIN,
  LOADING_KEYS,
  USER_TYPE,
  PURPLE,
  USER_SETTING_KEY, GREENWOODX_NETWORK_KEY, REQUEST_STATUS,
} from "../../lib/constants"
import {navigate} from "@reach/router"
import { useDispatch, useSelector } from 'react-redux'
import SmallConnectionModal, {SmallConnectionModalSkeleton} from "./features/network/ui/small_connection_modal"
import SmallViewAllModal from "./ui/small_view_all_modal"
import UpcomingCallModal, {UpcomingCallModalSkeleton} from "./ui/upcoming_call_modal"
import { getAuthToken } from "../../lib/auth"
import axios from "axios"
import SharedStyles from '../shared_styles'
import Util from "../../lib/util"
import moment from "moment"
import { ElementOrLoader } from "../util"
import FeedbackIcon from "@material-ui/icons/Feedback"
import { apiCancelRequest, apiPost } from "../../lib/api"


// Community Guideline
const CG_SUBMIT_LOADING = 'communityGuidelineSubmit';

const useStyles = makeStyles(theme => ({
  padding: {
    padding:theme.spacing(0, 1)
  },
  request: {
    width: '100%'
  },
  card: {
    height: '150px',
    width: '200px',
    marginLeft: theme.spacing(3)
  },
  horizontalContainerScroll: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display:'none'
    }
  },
  horizontalScroll: {
    display: 'inline-flex',
    paddingRight: theme.spacing(2.5),
    paddingLeft: theme.spacing(1),
  },
  section: {
    marginTop: theme.spacing(5)
  },
  marginRight: {
    marginRight: theme.spacing(2)
  },
  founderMessage: {
    marginBottom: theme.spacing(1)
  },
  founderMessageExpand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  founderMessageExpandOpen: {
    transform: 'rotate(180deg)',
  },
  founderMessageMedia: {
    height: '4vw',
    maxHeight: '300px',
    backgroundSize: 'contain'
  },

  placeholderPaper: {
    backgroundColor: '#fafafa',
    color: '#a0a0a0'
  },
  noPadding:{
    padding: 0
  },
  alertButtonAlert: {
    width: '100%'
  },

  communityGuidelineHeadline: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: PURPLE
  },
  communityGuidelineDescription: {

  },
  notificationListItem: {
    marginBottom: theme.spacing(1)
  }
}));

const Home = () => {

  const dispatch = useDispatch();
  const classes = useStyles();
  const styles = SharedStyles();
  const mixpanel = useContext(MixpanelContext);

  const {
    activeRequestMap,
    callLogs,
    user,
    connections,
    totalConnections,
    loading,
    userRequests,
    networkInfo} = useSelector(state => state.system);

  const [showCommunityGuidelines, setShowCommunityGuidelines] = useState(false);

  useEffect(()=>{
    dispatch(setAppBar({
      state: APP_BAR_STATE.HOME,
    }));

    if (networkInfo.Key == GREENWOODX_NETWORK_KEY) {
      setShowCommunityGuidelines(!user.SeenCommunityGuidelines);
    }
  }, [])

  const handleDecision = (decision, requestId) => {
    return new Promise((resolve, reject) => {
      const data = {
        external_id: requestId,
        action: decision
      }
      apiPost('request/decision', data)
        .then((response) => {
          if (response.error) {
            if (response.error_message) {
              alert(response.error_message);
            }
            return reject(response);
          }
          mixpanel.track('Request Decision', {
            'Request': requestId,
            'Decision': decision,
            'Surface': 'Home Modal'
          })
          return resolve(response);
        });
    });
  }

  const closeCommunityGuidelines = () => {
    dispatch(setLoading({key: CG_SUBMIT_LOADING, value: true}));
    const data = {
      key: USER_SETTING_KEY.SEEN_COMMUNITY_GUIDELINES,
      value: true
    };
    apiPost('user/update', data)
      .then((data) => {
        setShowCommunityGuidelines(false);
      })
      .catch(() => {
        dispatch(setSnackbar({open:true, text:'There was an error connecting to Greenwoodx. Please try again.'}))
      })
      .finally(() => {
        dispatch(setLoading({key: CG_SUBMIT_LOADING, value: false}));
      })
  }

  const getIncomingRequests = () => {
    const activeRequests = Object.keys(activeRequestMap).sort((a, b) => activeRequestMap[b].Created - activeRequestMap[a].Created);
    if (activeRequests.length === 0) {
      return null;
    }
    return (
      <Box className={classes.section}>
        <Box className={classes.padding}>
          <Typography variant="body1" className={styles.boldText}>
            Incoming Call Requests
          </Typography>
        </Box>
        <ElementOrLoader loading={loading[LOADING_KEYS.SECOND_LOAD]}>
          <Box className={classes.horizontalContainerScroll}>
            <Box className={classes.horizontalScroll}>
              { activeRequests.map((id, index) => (
                <SmallRequestModal
                  key={id}
                  request={activeRequestMap[id].Request}
                  confirmFn={(request) =>handleDecision(REQUEST_ACTION.ACCEPT, request.ExternalId)}
                  cancelFn={(request)=> {
                    if (window.confirm('Are you sure you want to decline this request?')) {
                      return handleDecision(REQUEST_ACTION.DECLINE, request.ExternalId);
                    }
                  }}
                  containerClass={classes.marginRight}/>
              ))}
              {activeRequests.length > 3 &&
              <SmallViewAllModal count={activeRequests.length} viewAllFn={()=>{}} />
              }
            </Box>
          </Box>
        </ElementOrLoader>
      </Box>
    );
  }

  const getSmallConnectionModals = () => {
    if (loading[LOADING_KEYS.SECOND_LOAD]) {
      return (
        <Box className={classes.horizontalScroll}>
          <SmallConnectionModalSkeleton containerClass={classes.marginRight} />
          <SmallConnectionModalSkeleton containerClass={classes.marginRight} />
        </Box>
      )
    }
    return (
      <Box className={classes.horizontalScroll}>
        { connections.map((connection, index) => (
          <SmallConnectionModal
            key={index}
            user={connection.User1['id'] == user['id']?connection.User2:connection.User1}
            containerClass={classes.marginRight}
            openFn={() => {
              mixpanel.track('Go to profile from home');
              const thisUser = connection.User1['id'] == user['id']?connection.User2:connection.User1;
              return navigate(`/app/network/profile/${thisUser['id']}`)
            }}
          />
        ))}
          <SmallViewAllModal
            count={totalConnections}
            viewAllFn={()=> {
              mixpanel.track('View All Network')
              navigate('/app/network')
            }} />

      </Box>
    )
  }

  const getNotifications = () => {
    const list = [];
    callLogs.forEach((callLog, index) => {
      // Skip if the call didn't happen yet.
      // Not sure how this makes sense, but it does with moment.
      if(moment(callLog.Request.TimeSlot).isAfter(moment())) {
        return;
      }
      // Skip if this is not a platform call.
      if (callLog.Request.Origin != REQUEST_ORIGIN.PLATFORM) {
        return;
      }
      // Skip if the users already submitted feedback.
      const userKey = user['id'] == callLog.User1['id']?'User1':'User2';
      if (callLog[`${userKey}SubmittedFeedback`]) {
        return;
      }
      // Show the feedback using the other User's info.
      const otherUser = userKey == 'User1'?callLog.User2:callLog.User1;
      list.push(
        <ListItem
          key={index}
          className={clsx([classes.noPadding,classes.notificationListItem])}
          button
          onClick={ () => {
            navigate(`/app/call/${callLog.ExternalId}`)
          }}
        >
          <Alert
            icon={ <Util.UserAvatar user={otherUser}/> }
            action={
              <KeyboardArrowRightIcon />
            }
            className={classes.alertButtonAlert}
            severity="warning">
            <AlertTitle>Did you speak with {otherUser.FirstName}? </AlertTitle>
            Tap here to submit your call feedback.
          </Alert>

        </ListItem>
      )
    })
    return (
      <List className={classes.noPadding}>
        {list}
      </List>
    );
  }

  const getUpcomingCallModals = () => {
    const list = [];
    if (loading[LOADING_KEYS.SECOND_LOAD]) {
      list.push(<UpcomingCallModalSkeleton key={0}/>);
      list.push(<UpcomingCallModalSkeleton key={1}/>);
      return list;
    }
    const upcomingCalls = [];
    const fifteenMinuteDelta = moment().subtract(15, 'minutes');
    // callLogs is sorted in descending order.
    for(let i=0; i< callLogs.length; i++) {
      if (moment(callLogs[i].Request.TimeSlot).isBefore(fifteenMinuteDelta)) {
        break;
      }
      upcomingCalls.push(callLogs[i]);
    }
    upcomingCalls.reverse().forEach((callLog, index) => {
      list.push(
        <UpcomingCallModal
          key={index}
          callLog={callLog}
        />
      );
    });
    if (list.length === 0) {
      return (
        <Paper variant="outlined" className={clsx([classes.placeholderPaper, styles.centerElements, styles.innerPadding])}>
          <Typography variant="subtitle1" className={styles.centerText}>
            You don't have any upcoming conversations.
          </Typography>
          <br />
          <Button onClick={()=>{
            mixpanel.track('Request Conversation from Upcoming Calls');
            navigate('/app/request/topic');
          }}>
            Request Conversation
          </Button>
        </Paper>
      );
    }
    return list;
  }

  const getHomePageMessage = () => {
    let message = null;
    if (user.Type == USER_TYPE.STUDENT && networkInfo.StudentHomePageMessage) {
      message = networkInfo.StudentHomePageMessage;
    } else if(user.Type == USER_TYPE.NORMAL && networkInfo.NormalHomePageMessage) {
      message = networkInfo.NormalHomePageMessage;
    }

    if (!message) return null;

    return (
      <Alert severity="success" icon={false} className={classes.founderMessage}>
        {message}
      </Alert>
    );
  }

  const userRequestCancelFn = (request) => {
    return new Promise((resolve, reject) => {
      if (window.confirm('Are you sure you want to cancel this request?')) {
        return apiCancelRequest(dispatch, request.ExternalId).then(() => {
          dispatch(setSnackbar({open:true, text: `Request canceled.`}));
          reject();
        });
      }
      return reject();
    });
  }

  const getUserRequests = () => {
    let userCalendarRequests = userRequests.filter((request) => request.Origin == REQUEST_ORIGIN.WEB && request.Status == REQUEST_STATUS.PENDING)
    if (userCalendarRequests.length == 0) {
      return null;
    }

    return (
      <Box className={classes.section}>
        <Box className={classes.padding}>
          <Typography variant="body1" className={styles.boldText}>
            Sent Calendar Requests
          </Typography>
        </Box>
        <ElementOrLoader loading={loading[LOADING_KEYS.SECOND_LOAD]}>
          <Box className={classes.horizontalContainerScroll}>
            <Box className={classes.horizontalScroll}>
              { userCalendarRequests.map((userRequest) => {
                return (
                  <SmallRequestModal
                    key={userRequest.ExternalId}
                    request={userRequest}
                    cancelFn={userRequestCancelFn}
                    openFn={()=>dispatch(setSnackbar({open:true, text: `${Util.getUserFullName(userRequest.RequestedUser)} hasn't replied to your request yet.`}))}
                    containerClass={classes.marginRight}/>
                  );
              })}
            </Box>
          </Box>
        </ElementOrLoader>
      </Box>
    );
  }


  return (
    <React.Fragment>
      {!networkInfo.SkipTopics && (networkInfo.ExpandNetworkEnabled || user.ExpandNetworkEnabled) && <HomePageHero />}
      {getNotifications()}
      {getHomePageMessage()}
      {getUserRequests()}
      {getIncomingRequests()}
      <Box className={classes.section}>
        <Box className={classes.padding}>
          <Typography variant="body1" className={styles.boldText}>
            My Network
          </Typography>
        </Box>
        <Box className={classes.horizontalContainerScroll}>
          {getSmallConnectionModals()}
        </Box>
      </Box>
      <Box className={classes.section}>
        <Box className={classes.padding}>
          <Typography variant="body1" className={styles.boldText}>
            Upcoming Calls
          </Typography>
        </Box>
        <List className={classes.padding}>
          {getUpcomingCallModals()}
        </List>

      </Box>
      <Dialog
        open={showCommunityGuidelines}
        onClose={closeCommunityGuidelines}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{style:{margin:'10px', maxWidth:'100%'}}}

      >
        <DialogTitle id="scroll-dialog-title">
          Community Guidelines
         </DialogTitle>
        <DialogContent dividers style={{padding:'0'}}>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            <Typography variant="caption">
            </Typography>
            <List>
              <ListItem key="note">
                <ListItemText
                  secondary="Greenwoodx wants to ensure that everyone in our community feels safe, heard and respected. Here are a few guidelines to help keep this culture intact:"/>
              </ListItem>
              {
                communityGuidelines.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={item['label']}
                      primaryTypographyProps={{className: classes.communityGuidelineHeadline}}
                      secondary={item['description']}
                    />
                  </ListItem>
                ))
              }
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ElementOrLoader loading={loading[CG_SUBMIT_LOADING]}>
            <Button
              onClick={closeCommunityGuidelines}
              color="primary">
              I Understand
            </Button>
          </ElementOrLoader>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Home;


const communityGuidelines = [
  {
    'label': 'This is not a mentorship program.',
    'description': 'We understand that every connection won’t be ideal for a long-term relationship so we allow professionals and students to decide who they want to add to their network for future conversations.'
  },
  {
    'label': 'Be nice.',
    'description': 'Naturally, you are occasionally going to meet someone who thinks differently than you. That is ok. Everyone on Greenwoodx is sharing their story. Don’t try to negate or conceal their experience, listen and empathize.'
  },
  {
    'label': 'Respect other people’s time.',
    'description': (
      <Typography variant="body2">
        No-Shows are a violation of our <a href="https://bit.ly/greenwoodx_terms_of_service" target="_blank">Terms of Service</a> and can result in being removed from Greenwoodx. If you are going to be late, notify the other person as soon as possible with the time you will be able to join. If you are no longer able to take the call, notify the other person within 24 hours that you need to reschedule.
        <br/><br/>
        Anyone under the age of 18 years old is not permitted on Greenwoodx as outlined in our <a href="https://bit.ly/greenwoodx_terms_of_service" target="_blank">Terms of Service</a>.
      </Typography>
    ),
  },
  {
    'label': 'Get to know each other.',
    'description': 'Greenwoodx is about making connections and growing your network, not job openings. You will be speaking with real people and not company recruiters. Refrain from asking for a job, internship, or referral.'
  },
  {
    'label': 'Be prepared and engaged.',
    'description': (
      <Typography variant="body2">
        Come to the video calls ready to explore ideas or pose questions you may want to know about the other person. If you need some pointers, check out the <a href="http://bit.ly/greenwoodx_call_tips" target="_blank">Call Tips</a>.
      </Typography>
    )
  },
  {
    'label': 'Follow local laws.',
    'description': 'Any discrimination, hate speech, bullying, threats of physical violence (directly or indirectly), or harassment will not be tolerated and will result in immediate removal from Greenwoodx.'
  },
  {
    'label': 'Take action.',
    'description': (
      <Typography variant="body2">
        If you see or experience any violations outlined above, please notify our team at <a href="mailto://grnwdx@gmail.com">grnwdx@gmail.com</a> or use the feedback button (<span><FeedbackIcon style={{position:'relative', top:'10px'}}/></span>) in the settings menu.
      </Typography>
    )
  },
];
