import React, {useEffect, useState, useContext} from 'react';
import {MixpanelContext} from '../../../../lib/tracker'
import {
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  Grid,
  Box,
  TextField,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Button, Chip, Container, CssBaseline, makeStyles, Paper, Typography, CircularProgress, ListItemIcon,
} from "@material-ui/core"
import BeenhereIcon from '@material-ui/icons/Beenhere';
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CommentIcon from '@material-ui/icons/Comment';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import PersonIcon from '@material-ui/icons/Person';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import {useDispatch, useSelector} from "react-redux"
import Util from "../../../../lib/util"

import Layout from '../../../layout'
import clsx from "clsx"
import { ElementOrLoader } from "../../../util"

import {
  createEnum,
  PURPLE,
  LIGHT_BLUE,
  API_ADDRESS,
  REQUEST_ORIGIN,
  REQUEST_STATUS,
  USER_TYPE, APP_BAR_STATE, REQUEST_ACTION,
} from "../../../../lib/constants"

import { getAuthToken } from "../../../../lib/auth"
import axios from "axios"
import moment from "moment"
import { setAppBar } from "../system/system_slice"
import UserListItem from "./ui/user_list_item"

const useStyles = makeStyles((theme) =>({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    height: '100%',
    overflow: 'hidden',
  },
  whenContainer: {
    background: "#e8f1fb",
    padding: theme.spacing(1),
    width: '100%'
  },
  noteContainer: {
    background: "#e8f1fb",
    padding: theme.spacing(1),
    width: '100%'
  },
  whenBold: {
    fontWeight: 'bold',
    marginRight: theme.spacing(2)
  },
  centerElements: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  internalPadding: {
    padding: theme.spacing(2)
  },
  green: {
    color: theme.palette.success.dark
  },
  red: {
    color: theme.palette.error.dark
  },
  acceptedPaper: {
    // background: theme.palette.success.light
  },
  details: {
    width: '100%',
    borderColor: theme.palette.divider
  }
}));

const DECISION_STATE = createEnum(['INITIAL', 'LOADING', 'ACCEPTED', 'DECLINED'])

const PendingRequest = ({navigate, requestId}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const mixpanel = useContext(MixpanelContext);

  const {topicMap} = useSelector(state => state.system)


  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [request, setRequest] = useState({});
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [note, setNote] = useState(null);
  const [requestOrigin, setRequestOrigin] = useState(REQUEST_ORIGIN.WEB);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [declineDialogOption, setDeclineDialogOptions] = useState({});
  const [decisionState, setDecisionState] = useState(DECISION_STATE.INITIAL);

  useEffect(()=>{
    dispatch(setAppBar({
      state: APP_BAR_STATE.BACK_LABEL,
      label: 'Pending Request',
      backLocation: '/app'
    }));



    const config = {
      headers: { Authorization: `Bearer ${getAuthToken()}` }
    };
    axios.get(`${API_ADDRESS}/request/get-by-id?id=${requestId}`, config)
      .then((response) => {
        if (response.data.error) {
          return setNotFound(true);
        }
        setRequest(response.data.active_request.Request);
        let m = moment(response.data.active_request.Request.TimeSlot);
        setDate(m.format('dddd, MMMM Do'))
        setTime(`${m.format('h:mma')} - ${m.add(30, 'minutes').format('h:mma')} ${Util.getTimezone()}`);
        setNote(response.data.active_request.Request.Note);

        switch(response.data.active_request.Request.Status) {
          case REQUEST_STATUS.FULFILLED:
            setDecisionState(DECISION_STATE.ACCEPTED);
            break;
          case REQUEST_STATUS.UNFULFILLED:
            setDecisionState(DECISION_STATE.DECLINED);
            break;
        }
      })
      .finally(()=>setLoading(false));
  }, [])

  const handleDecision = (decision) => {
    const config = {
      headers: { Authorization: `Bearer ${getAuthToken()}` }
    };
    const data = {
      external_id: requestId,
      action: decision
    }
    setDecisionState(DECISION_STATE.LOADING);
    axios.post(`${API_ADDRESS}/request/decision`, data, config)
      .then((response) => {
        mixpanel.track('Request Decision', {
          'Request': requestId,
          'Decision': decision,
          'Surface' : 'Pending Request'

        })
        if (response.data.error) {
          if (response.data.error_message) {
            alert(response.data.error_message);
          }
          setDecisionState(DECISION_STATE.INITIAL);
          return;
        }
        setDecisionState(decision == REQUEST_ACTION.ACCEPT?DECISION_STATE.ACCEPTED:DECISION_STATE.DECLINED);

      }).catch(()=>setDecisionState(DECISION_STATE.INITIAL))

  }

  const getDeclineBody = () => {
    return (
      <React.Fragment>
        <Typography variant="p">dfdf</Typography>
        <FormControl component="fieldset">
          <RadioGroup aria-label="gender" name="gender1"
                      // value={value}
                      // onChange={handleChange}
          >
            <FormControlLabel value="female" control={<Radio />} label="This time doesn't work for me" />
            <FormControlLabel value="male" control={<Radio />} label="Male" />
            <FormControlLabel value="other" control={<Radio />} label="Ask them to reschedule" />
            <FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" />
          </RadioGroup>
        </FormControl>
        <TextField
          label="Multiline"
          multiline
          rowsMax="4"
        />
      </React.Fragment>
    );
  }

  const getTitleSection = () => {
    let originLabel = request.Origin == REQUEST_ORIGIN.WEB? 'Web' : 'Greenwoodx';
    let background = request.Origin == REQUEST_ORIGIN.WEB? LIGHT_BLUE : PURPLE;
    return (
      <Box className={clsx([classes.centerElements])} style={{background: background}}>
        <Typography variant="subtitle2" style={{color:'white'}}>
          New {originLabel} Request
        </Typography>
      </Box>
    )
  }

  const getNotFoundError = () => {
    return (
      <React.Fragment>
        <Typography variant="h5">
          Sorry, I can't find that request.
        </Typography>
        <Button
          variant='outlined'
          onClick={()=>navigate('/app')}
        >
          Go back to Greenwoodx
        </Button>
      </React.Fragment>
    )
  }

  const getDecisionElement = () =>{
    switch(decisionState) {
      case DECISION_STATE.INITIAL:
        return (
          <React.Fragment>
            <Button
              onClick={()=>handleDecision(REQUEST_ACTION.ACCEPT)}
              fullWidth
            >
              Accept
            </Button>
            <Button
              onClick={()=>handleDecision(REQUEST_ACTION.DECLINE)}
              fullWidth
              >
              I can't make it
            </Button>
          </React.Fragment>
        )
        break;
      case DECISION_STATE.LOADING:
        return (
          <CircularProgress size={24} />
        );
      case DECISION_STATE.ACCEPTED:
        return (
          <Paper variant="outlined" className={clsx([classes.acceptedPaper, classes.centerElements, classes.internalPadding])}>
            <BeenhereIcon className={classes.green}/>
            <Typography variant="h5" className={classes.white}>
              Accepted
            </Typography>
            <Typography variant="subtitle1" className={classes.white}>
              You have accepted this request.
            </Typography>
          </Paper>
        )
      case DECISION_STATE.DECLINED:
        return (
          <Paper variant="outlined" className={clsx([classes.acceptedPaper, classes.centerElements, classes.internalPadding])}>
            <BeenhereIcon className={classes.red}/>
            <Typography variant="h5" className={classes.white}>
              Declined
            </Typography>
            <Typography variant="subtitle1" className={classes.white}>
              You have declined this request.
            </Typography>
          </Paper>
        )
    }
  }

  const getBody = () => {
    let note_element = null;
    if (request.Note) {
      note_element = (
        <ListItem>
          <ListItemIcon>
            <CommentIcon />
          </ListItemIcon>
          <ListItemText primary={request.Note} />
        </ListItem>
      );
    }

    let originChip = null;
    if (request.Origin == REQUEST_ORIGIN.PLATFORM) {
      originChip = ( <Chip label={request.Topic.Label} color="primary"/> );
    } else if(request.Origin == REQUEST_ORIGIN.WEB) {
      originChip = ( <Chip label="Direct Request" color="primary" variant="outlined"/> );
    }

    let extra = [];
    if (request.Type == USER_TYPE.STUDENT) {
      extra.push(
        <ListItem key="student">
          <ListItemIcon>
            <LocalLibraryIcon />
          </ListItemIcon>
          <ListItemText primary="Student" />
        </ListItem>
      );
    }

    return (
      <Paper className={classes.paper} elevation={1} >
        <Grid container spacing={1} className={classes.internalPadding} >
          <Box className={classes.details} borderBottom={1}>
              <List>
                <UserListItem key="user" user={request.User} />
                <ListItem>
                  <ListItemIcon>
                    <LocalOfferIcon />
                  </ListItemIcon>
                  <ListItemText primary={originChip} />
                </ListItem>
                {note_element}
                <ListItem>
                  <ListItemIcon>
                    <CalendarTodayIcon />
                  </ListItemIcon>
                  <ListItemText primary={date} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <AccessTimeIcon />
                  </ListItemIcon>
                  <ListItemText primary={time} />
                </ListItem>
              </List>
          </Box>
          <Grid item xs={12} className={classes.centerElements}>
            {getDecisionElement()}
          </Grid>
        </Grid>
      </Paper>
    );
  }

  return (
    <React.Fragment>
        <Container maxWidth="xs">
          <ElementOrLoader loading={loading}>
            {notFound && getNotFoundError()}
            {!notFound && getBody()}
          </ElementOrLoader>
        </Container>
      <Dialog
        onClose={()=>setDialogOpen(false)}
        aria-labelledby="simple-dialog-title"
        open={dialogOpen}>
        <DialogTitle id="simple-dialog-title">Decline Request</DialogTitle>
        <Box className={classes.dialog}>
          {getDeclineBody()}
          <Button>
            Confirm
          </Button>
        </Box>
      </Dialog>
    </React.Fragment>

);
}

export default PendingRequest;
