import React, {useState, useEffect, useContext} from 'react';
import {MixpanelContext} from "../../../../lib/tracker"
import clsx from 'clsx';
import {
  Box,
  Avatar,
  Container,
  Grid,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
  ListSubheader
} from "@material-ui/core"
import SharedStyles from '../../../shared_styles'
import {setAppBar} from "../system/system_slice"
import { API_ADDRESS, APP_BAR_STATE, REQUEST_ACTION } from "../../../../lib/constants"
import { useDispatch, useSelector } from "react-redux"
import SmallRequestModal from "../request/ui/small_request_modal"
import { navigate } from "@reach/router"
import { getAuthToken } from "../../../../lib/auth"
import axios from "axios"
import SmallViewAllModal from "../../ui/small_view_all_modal"

const sampleRequest = {
  'Request': {
    'User': {
      'FirstName': 'John',
      'LastName': 'Doe',
      'ProfilePic': 'https://media-exp1.licdn.com/dms/image/C5603AQFhoMRZgG9mig/profile-displayphoto-shrink_200_200/0?e=1596067200&v=beta&t=yac7NqPdt-EwqdcYvOoxK1iaXUHVsZp3d51KzBzsRFY'
    },
    'TimeSlot': {
      'DateTime': new Date()
    }
  },
}
let sampleRequests = [sampleRequest, sampleRequest, sampleRequest, sampleRequest, sampleRequest ];


const useStyles = makeStyles((theme) => ({
  avatar: {
    height: '56px',
    width: '56px'
  }
}))

const Requests = () => {
  const dispatch = useDispatch();
  const styles = SharedStyles();
  const classes = useStyles();
  const mixpanel = useContext(MixpanelContext);

  const {activeRequestMap} = useSelector(state => state.system)


  useEffect(() => {
    dispatch(setAppBar({
      state: APP_BAR_STATE.BACK_LABEL,
      label: 'Requests'
    }));
  }, [])

  const handleDecision = (decision, requestId) => {
    return new Promise((resolve, reject) => {
      const config = {
        headers: { Authorization: `Bearer ${getAuthToken()}` }
      };
      const data = {
        external_id: requestId,
        action: decision
      }
      axios.post(`${API_ADDRESS}/request/decision`, data, config)
        .then((response) => {
          if (response.data.error) {
            if (response.data.error_message) {
              alert(response.data.error_message);
            }
            return reject(response.data);
          }
          mixpanel.track('Request Decision', {
            'Request': requestId,
            'Decision': decision,
            'Surface': 'Full Requests Page'
          })
          return resolve(response.data);
        })
    });
  }

const getActiveRequestsElements = () => {
  const activeRequests = Object.keys(activeRequestMap).sort((a, b) => activeRequestMap[b].Created - activeRequestMap[a].Created);

  return activeRequests.map((id, index) => (
    <SmallRequestModal
      key={id}
      fullWidth
      activeRequest={activeRequestMap[id]}
      confirmFn={(request) =>handleDecision(REQUEST_ACTION.ACCEPT, request.ExternalId)}
      cancelFn={(request)=>handleDecision(REQUEST_ACTION.DECLINE, request.ExternalId)}
      />
  ));
}


return (
    <Container maxWidth="sm">
      {getActiveRequestsElements()}
    </Container>
  );
}

export default Requests;
