import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  CssBaseline,
  makeStyles,
  Paper,
  List,
  ListItem,
  ListSubheader,
  ListItemIcon,
  ListItemText,
  Typography, Grid, ListItemAvatar,
} from "@material-ui/core"
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
import NoteIcon from '@material-ui/icons/Note';
import HistoryIcon from '@material-ui/icons/History';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import DescriptionIcon from '@material-ui/icons/Description';
import FeedbackIcon from "@material-ui/icons/Feedback"
import FlareIcon from '@material-ui/icons/Flare';




import { setAppBar } from "./features/system/system_slice"
import { APP_BAR_STATE, FRONTEND_URL, PURPLE, USER_TYPE } from "../../lib/constants"
import { useDispatch , useSelector} from "react-redux"
import { navigate } from "@reach/router"
import SharedStyles from '../shared_styles'
import {setSnackbar} from "./features/system/system_slice"
import Util from '../../lib/util';

const useStyles = makeStyles( theme => ({
  inviteCountAvatar: {
    background: theme.palette.primary.main,
  }
}));

const Settings = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const styles = SharedStyles();
  const {user, networkInfo} = useSelector(state => state.system);

  useEffect(() => {
    dispatch(setAppBar({
      state: APP_BAR_STATE.LABEL,
      label: 'Settings'
    }));
  }, [])

  const openAvailability = () => {
    return navigate('/app/settings/availability');
  }

  const openStatement = () => {
    return navigate('/app/settings/statement');
  }

  const openLinkedIn = () => {
    return navigate('/app/settings/linkedin');
  }

  const openInterests = () => {
    return navigate('/app/settings/interests');
  }

  const openKnownTopics = () => {
    return navigate('/app/settings/known');
  }

  const openCallHistory = () => {
    return navigate('/app/settings/history');
  }

  const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  const handleCopyButton = () => {
    copyToClipboard(`${FRONTEND_URL}/?code=${user.InviteCode}`);
    dispatch(setSnackbar({
      open: true,
      text: 'Special invite link copied!'
    }))
  }

  return (
    <Container maxWidth="sm">
      <List dense>
        <ListItem
          className={styles.paperListItem}
        >
          <ListItemAvatar>
            <Util.UserAvatar
              className={classes.avatar}
              user={user}/>
          </ListItemAvatar>
          <ListItemText
            primary={Util.getUserFullName(user)}
            secondary={user.Type == USER_TYPE.NORMAL?"Professional":"Student"}
          />
        </ListItem>
        <ListSubheader>About Me</ListSubheader>
        <ListItem
          button
          onClick={openStatement}
        >
          <ListItemIcon>
            <NoteIcon />
          </ListItemIcon>
          <ListItemText primary={"Statement"} secondary={user.Statement} />
        </ListItem>
        {networkInfo.ShowLinkedIn &&
          <ListItem
            button
            onClick={openLinkedIn}
          >
            <ListItemIcon>
              <LinkedInIcon/>
            </ListItemIcon>
            <ListItemText primary={"LinkedIn URL"} secondary={user.LinkedIn}/>
          </ListItem>
        }
        {networkInfo.ShowInviteLink &&
          <ListItem
            button
            onClick={handleCopyButton}
          >
            <ListItemAvatar>
              <Avatar className={classes.inviteCountAvatar}>{user.InvitedCount}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Copy Invite Link"}
                          secondary="Use this link to invite people to join you and expand their network on Greenwoodx."/>
          </ListItem>
        }
        <ListItem
          button
          onClick={openCallHistory}
        >
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          <ListItemText primary={"Call History"} secondary="See a list of everyone you've spoken to on Greenwoodx!" />
        </ListItem>
        <ListSubheader disableSticky>Preferences</ListSubheader>
        <ListItem
          button
          onClick={openAvailability}
        >
          <ListItemIcon>
            <CalendarTodayIcon />
          </ListItemIcon>
          <ListItemText primary={"General Availability"} secondary="" />
        </ListItem>
        {!networkInfo.SkipTopics &&
          <ListItem
            button
            onClick={openKnownTopics}
          >
            <ListItemIcon>
              <LocalOfferIcon/>
            </ListItemIcon>
            <ListItemText
              primary={"Known Topics"}
              secondary={`${Object.keys(user.KnownTopics).length} selected`}/>
          </ListItem>
        }
        {!networkInfo.SkipTopics &&
          <ListItem
            button
            onClick={openInterests}
          >
            <ListItemIcon>
              <LocalOfferIcon/>
            </ListItemIcon>
            <ListItemText
              primary={"Interests"}
              secondary={`${Object.keys(user.Interests).length} selected`}/>
          </ListItem>
        }
        <ListSubheader disableSticky >{networkInfo.Name}</ListSubheader>
        {networkInfo.CommunityGuidelinesUrl &&
          <ListItem
            button
            onClick={() => window.open(networkInfo.CommunityGuidelinesUrl, '_blank')}
          >
            <ListItemIcon>
              <DescriptionIcon/>
            </ListItemIcon>
            <ListItemText primary={"Community Guidelines"} secondary=""/>
          </ListItem>
        }
        <ListItem
          key="seperator"
          style={{height: '100px'}}
        />
        <ListSubheader
          disableSticky
          style={{textAlign: 'center', color: PURPLE}}
        >
          <Util.PoweredByGreenwoodx networkKey={networkInfo.Key}/>
        </ListSubheader>
        <ListItem
          button
          onClick={()=>window.open('http://bit.ly/Greenwoodx_app_feedback', '_blank')}
        >
          <ListItemIcon>
            <FeedbackIcon />
          </ListItemIcon>
          <ListItemText primary="Send Feedback / Report an Issue" secondary="" />
        </ListItem>
        <ListItem
          button
          onClick={()=>window.open('https://bit.ly/greenwoodx_privacy_policy', '_blank')}
        >
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary={"Privacy Policy"} secondary="" />
        </ListItem>
      <ListItem
        button
        onClick={()=>window.open('https://bit.ly/greenwoodx_terms_of_service', '_blank')}
      >
        <ListItemIcon>
          <DescriptionIcon />
        </ListItemIcon>
        <ListItemText primary={"Terms of Service"} secondary="" />
      </ListItem>
    </List>
    </Container>
  );
}

export default Settings;

