// import ...
import React, { Component, useEffect, useState } from "react"
import { navigate } from "gatsby"
import {isAuthenticated} from "../lib/auth"

const AuthRoute = ({ component: Component, location, callbackUrl, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [localIsAuthenticated, setLocalIsAuthenticated] = useState(false);

  useEffect(() => {
    setLocalIsAuthenticated(isAuthenticated());
    setLoading(false)
  }, [])
  if (loading) return null;

  if (!localIsAuthenticated && location && location.pathname !== `/login`) {
    navigate(`/login`+(callbackUrl?`?url=${callbackUrl}`:''));
    return null;
  }
  return <Component {...rest} />
}
export default AuthRoute
