import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import {
  Box,
  Avatar,
  Container,
  Grid,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
  ListSubheader
} from "@material-ui/core"
import SharedStyles from '../../../shared_styles'
import {setAppBar} from "../system/system_slice"
import { APP_BAR_STATE} from '../../../../lib/constants'
import { useDispatch, useSelector } from "react-redux"
import SmallRequestModal from "../request/ui/small_request_modal"
import { navigate } from "@reach/router"
import UpcomingCallModal from "../../ui/upcoming_call_modal"
import moment from "moment"

const sampleRequest = {
  'Request': {
    'User': {
      'FirstName': 'John',
      'LastName': 'Doe',
      'ProfilePic': 'https://media-exp1.licdn.com/dms/image/C5603AQFhoMRZgG9mig/profile-displayphoto-shrink_200_200/0?e=1596067200&v=beta&t=yac7NqPdt-EwqdcYvOoxK1iaXUHVsZp3d51KzBzsRFY'
    },
    'TimeSlot': {
      'DateTime': new Date()
    }
  },
}
let sampleRequests = [sampleRequest, sampleRequest, sampleRequest, sampleRequest, sampleRequest ];


const useStyles = makeStyles((theme) => ({
}))

const CallHistory = () => {
  const dispatch = useDispatch();
  const styles = SharedStyles();
  const classes = useStyles();

  const {callLogs} = useSelector(state => state.system);

  useEffect(() => {
    dispatch(setAppBar({
      state: APP_BAR_STATE.BACK_LABEL,
      label: 'Call History'
    }));
  }, [])

  const getUpcomingCallModals = () => {
    const list = []
    callLogs.forEach((callLog, index) => {
      if (moment(callLog.Request.TimeSlot).isAfter(moment())) {
        return;
      }
      list.push(
        <UpcomingCallModal
          key={index}
          callLog={callLog}
          noShadow
        />
      );
    });
    if (callLogs.length === 0) {
      return (
        <Paper variant="outlined" className={clsx([styles.centerElements, styles.innerPadding])}>
          <Typography variant="subtitle1" style={{color:'grey'}}>As you get and confirm calls they will show up here.</Typography>
        </Paper>
      );
    }
    return list;
  }

  return (
    <Container maxWidth="sm" disableGutters>
      <List>
        {getUpcomingCallModals()}
      </List>
    </Container>
  );
}

export default CallHistory;
