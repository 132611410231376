import React, {useState, useEffect, useContext} from 'react';
import {MixpanelContext} from "../../../../lib/tracker"
import clsx from 'clsx';
import { Box, Button, Chip, Container, CssBaseline, makeStyles, Paper, Typography } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import Header from "../../../header"
import { navigate } from "@reach/router"


import DesktopTimePicker from "./desktop_time_picker"
import { DatePickerToolbar } from "@material-ui/pickers/DatePicker/DatePickerToolbar"
import { ElementOrLoader } from "../../../util"

import Layout from '../../../layout'
import { setAppBar, setSnackbar, setLoading, setSystemData} from "../system/system_slice"
import { API_ADDRESS, APP_BAR_STATE } from "../../../../lib/constants"
import axios from "axios"
import moment from "moment"
import { getAuthToken } from "../../../../lib/auth"
import { apiGet, apiPost } from "../../../../lib/api"

const useStyles = makeStyles((theme) =>({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    height: '100%',
  },
  // container: {
  //   position: 'absolute',
  //   margin: '0 auto',
  //   top: '70px',
  //   bottom: 0,
  //   paddingBottom: '24px'
  // },
  centerElements: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  details: {
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%',
    borderColor: theme.palette.divider
  },
}));

const LOADING_KEY = 'loadingSelectTime';

const SelectTime = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const mixpanel = useContext(MixpanelContext);
  const {requestTopic, addedNote, selectedTimeslot} = useSelector(state => state.request);
  const {loading} = useSelector(state => state.system);
  const [timeslotsMap, setTimeslotsMap] = useState({});

  useEffect(() => {
    dispatch(setLoading({key: LOADING_KEY, value: true}));

    if (!requestTopic) {
        navigate('/app/request/topic', {replace: true});
        dispatch(setLoading({key: LOADING_KEY, value: false}));
        return;
    }

    dispatch(setAppBar({
      state: APP_BAR_STATE.BACK_LABEL,
      label: 'Select a Time'
    }));

    let startDate = moment();
    let endDate = moment(startDate).add(2, 'week');
    const startDateString = startDate.utc().format();
    const endDateString = endDate.utc().format();

    const params = {
      id: requestTopic['Key'],
      start: startDateString,
      end: endDateString
    };
    apiGet('request/topic-timeslots', params)
      .then((response) => {
        if(response.error) {
          return alert(response.error_message);
        }

        let localTimeSlotsMap = {};
        response.timeslots.forEach((timeslot) => {
          const date = moment(timeslot).format('YYYY-MM-DD');
          if (!localTimeSlotsMap[date]) {
            localTimeSlotsMap[date] = [];
          }
          localTimeSlotsMap[date].push(timeslot)
        });
        setTimeslotsMap(localTimeSlotsMap);
        dispatch(setLoading({key: LOADING_KEY, value: false}));
      })
  }, [])

  const submitRequest = () => {
    const data = {
      timeslot: selectedTimeslot,
      topic: requestTopic.Key,
      note: addedNote
    };


    apiPost('request/send-topic-request', data)
      .then((response)=> {
        if (response.error) {
          alert(response.error_message);
          return;
        }

        mixpanel.track('On-Demand Request Sent', {
          'Timeslot': moment(selectedTimeslot).format('YYYY-MM-DDThh:mm:ss'),
          'Topic': requestTopic.Key
        });

        dispatch(setSystemData({key: 'userRequests', value: response.user_requests}));
        dispatch(setSnackbar({
          open: true,
          text: 'Your request has been sent 🎉'
        }))
        return navigate('/app');

      })
      .catch(()=>{
        alert('There was a problem reaching the server. Try again please.');
      })
  }

  return (
    <Container maxWidth="sm">
      <Paper className={classes.paper} elevation={1} >
        <Box className={clsx([classes.centerElements,classes.details])} borderBottom={1}>
          {addedNote &&
            <Typography variant="subtitle1">
              {addedNote}
            </Typography>
          }
          {requestTopic &&
            <Chip
              label={requestTopic["Label"]}
              className={classes.chip}
              variant="default"
              color="primary"
            />
          }
          <Typography variant="h5">
            When are you available?
          </Typography>
        </Box>
        <Box className={classes.centerElements}>
          <ElementOrLoader loading={loading[LOADING_KEY]}>
            <DesktopTimePicker
              timeslotsMap={timeslotsMap}
              onConfirmTimeslot={submitRequest}
            />
          </ElementOrLoader>
        </Box>
      </Paper>
    </Container>
  );
}

export default SelectTime;
