import React, { useEffect, useReducer, useState, useContext} from "react"
import { navigate } from "@reach/router"
import { MixpanelContext } from '../../../../lib/tracker';
import {
  Fab,
  CircularProgress,
  Box, Chip,
  Button,
  Container,
  Grid,
  CssBaseline, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary,
  makeStyles,
  Paper,
  Typography,
  TextField,
} from "@material-ui/core"

import {
  Alert,
} from '@material-ui/lab';


import Header from '../../../header'
import axios from "axios"
import { API_ADDRESS, APP_BAR_STATE, STATEMENT_CHAR_LIMIT, PURPLE} from "../../../../lib/constants"
import { setAppBar, setLoading } from "../system/system_slice"
import { setRequestTopic, setAddedNote, saveTopics } from "./request_slice"
import { useDispatch, useSelector } from 'react-redux'
import SharedStyles from '../../../shared_styles'
import clsx from 'clsx';
import { apiGet } from "../../../../lib/api"
import moment from "moment"
import { ElementOrLoader } from "../../../util"


const MAX_NOTE_LENGTH = 200;

const useStyles = makeStyles((theme) =>({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  expansionDetailOverride: {
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(.25, .25),
    '&:focus': {
      background: PURPLE,
      color: 'white'
    }
  },
  stepButtons: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    width: '100%'
  },
  sectionHeading: {
    fontWeight: ' bold'
  }
}));

const LOADING_KEY = 'loadingSelectTopic';

const SelectTopic = () => {
  const classes = useStyles();
  const styles = SharedStyles();
  const dispatch = useDispatch();
  const mixpanel = useContext(MixpanelContext);

  const {user, canonicalTopicMap, loading} = useSelector(state => state.system);
  const {requestTopic, addedNote} = useSelector(state => state.request);

  const [topicMap, setTopicMap] = useState({});
  const [activeTopics, setActiveTopics] = useState(null);

  const [requestNote, setRequestNote] = useState("");
  const [seeAllTopicChips, setSeeAllTopicChips] = useState(false);
  const [selectedSuggestionTopic, setSelectedSuggestionTopic] = useState(false);

  const [expanded, setExpanded] = useState(null);
  const handleExpandedChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    dispatch(setRequestTopic(null));
  };

  useEffect(() => {
    dispatch(setAppBar({
      state: APP_BAR_STATE.LABEL,
      label: 'New Conversation'
    }));


    let startDate = moment();
    let endDate = moment(startDate).add(2, 'week');
    const startDateString = startDate.utc().format();
    const endDateString = endDate.utc().format();
    dispatch(setLoading({key:LOADING_KEY, value: true}));
    const params = {
      start: startDateString,
      end: endDateString
    };
    apiGet(`request/topic-map`, params)
      .then((data) => {
        if (data.error) {
          return;
        }
        setTopicMap(data.topic_map);
        dispatch(setLoading({key:LOADING_KEY, value: false}));
      });
  }, []);

  useEffect(() => {
    if (!canonicalTopicMap) return;
    const topLevelMap = {};
    Object.keys(topicMap).forEach((key) => {
      const newTopLevel = canonicalTopicMap[canonicalTopicMap[key].ParentId];
      // If the parent key doesn't exist yet, add it to the map and clear the
      // SubTopics.
      if (!topLevelMap[newTopLevel.Key]) {
        topLevelMap[newTopLevel.Key] = Object.assign({}, newTopLevel);
        topLevelMap[newTopLevel.Key].SubTopics = [];
      }
      topLevelMap[newTopLevel.Key].SubTopics.push(canonicalTopicMap[key]);
    })

    const newTopics = Object.keys(topLevelMap).map((topLevelKey) => topLevelMap[topLevelKey]);
    setActiveTopics(newTopics);
  }, [canonicalTopicMap, topicMap])

  const handleTopicSelect = (topic, fromSuggested) => {
    setSelectedSuggestionTopic(fromSuggested);
    dispatch(setRequestTopic(topic));
  }
  const handleNextButton = () => {
    mixpanel.track('On-Demand Select Topic', {
      'Suggested Topic': selectedSuggestionTopic,
      'Added Note': requestNote.length != 0
    });
    dispatch(setAddedNote(requestNote));
    navigate('/app/request/time');
    return true;
  }


  const getTopicChips = () => {
    if (!activeTopics) {
      return <CircularProgress size={24} />;
    }
    let containing_elements = [];
    activeTopics.forEach((topLevel) => {
      let elements = [];
      topLevel['SubTopics'].forEach((main) => {
        let variant = 'outlined';
        if (requestTopic && requestTopic["Key"] == main["Key"])
          variant = "default";
        elements.push(
          <Chip
            key={main["Key"]}
            label={main["Label"]}
            className={classes.chip}
            onClick={() => handleTopicSelect(main, false)}
            variant={variant}
          />
        );
      });
      containing_elements.push(
        <ExpansionPanel key={topLevel['Label']} expanded={expanded === topLevel['Label']}
                        onChange={handleExpandedChange(topLevel['Label'])}>
          <ExpansionPanelSummary>
            <Typography component="h6" variant="subtitle2">
              {topLevel['Label']}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionDetailOverride}>
            {elements}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    });
    if (!activeTopics.length) {
      containing_elements.push(
        <Alert severity="warning" key="alert">
          People are still joining the network. Give them some time to also get set up.
        </Alert>
      )
    }
    return (
      <Box className={classes.topicSectionBox}>
        {containing_elements}
      </Box>

    );
  }

  const handleTextField = (event) => {
    if (event.target.value.length > MAX_NOTE_LENGTH) return;
    setRequestNote(event.target.value);
  }

  const getSuggestedTopicChips = () => {
    if (!canonicalTopicMap) return;
    if (seeAllTopicChips) {
      return getTopicChips();
    } else {
      if (!topicMap) return;
      const topicMapKeys = Object.keys(topicMap);
      return user.Interests.map((key) => {
        if (topicMapKeys.indexOf(key.toString()) == -1) {
          return;
        }
        let variant = 'outlined';
        if (requestTopic && requestTopic["Key"] == key)
          variant = "default";
        return (
          <Chip
            key={key}
            label={canonicalTopicMap[key].Label}
            className={classes.chip}
            onClick={() => handleTopicSelect(canonicalTopicMap[key], true)}
            variant={variant}
          />
        );
      });
    }
  }

  return (
    <Grid container  className={styles.innerPadding}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" className={classes.sectionHeading}>
          I want to talk about...
        </Typography>
      </Grid>
      <Grid item xs={12} className={styles.bottomMargin}>
          <TextField
          id="note"
          name="note"
          variant="outlined"
          fullWidth
          required
          value={requestNote}
          onChange={handleTextField}
          margin="dense"
          multiline
          rows={4}
          rowsMax={7}
          helperText={`${MAX_NOTE_LENGTH-requestNote.length} characters left.`}
        />
      </Grid>
      <Grid item xs={7}>
        <Typography variant="subtitle1" className={classes.sectionHeading}>
          with people in...
        </Typography>
      </Grid>
      <Grid item xs={5} style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button onClick={()=>setSeeAllTopicChips(!seeAllTopicChips)}>
          See {seeAllTopicChips?"Less":"More"}
        </Button>
      </Grid>
      <ElementOrLoader loading={loading[LOADING_KEY]}>
        <Grid item xs={12} className={styles.bottomMargin}>
          <Box>
            {getSuggestedTopicChips()}
          </Box>
        </Grid>
        <Grid item xs={12} >
            <Box className={classes.stepButtons}>
              <Button
                variant="contained"
                disabled={!requestTopic}
                color="primary"
                onClick={handleNextButton}
                className={styles.onlyActionButton}
              >
                Next
              </Button>
            </Box>
        </Grid>
      </ElementOrLoader>
    </Grid>
  );
}

export default SelectTopic;
