import React, { useState, useEffect, useContext } from "react"
import {MixpanelContext} from "../../lib/tracker"
import {
  Avatar,
  Paper,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  ListSubheader,
} from "@material-ui/core"
import {
  Alert,
} from '@material-ui/lab';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SharedStyles from '../shared_styles'
import { setAppBar } from "./features/system/system_slice"
import {
  APP_BAR_STATE,
  LOADING_KEYS,
} from "../../lib/constants"
import { useDispatch, useSelector } from "react-redux"
import ConnectionRow, {ConnectionRowSkeleton} from "./features/network/ui/connection_row"
import { navigate } from "@reach/router"


const useStyles = makeStyles((theme) => ({
}))

const Network = () => {
  const dispatch = useDispatch();
  const styles = SharedStyles();
  const classes = useStyles();
  const mixpanel = useContext(MixpanelContext);

  const {user, activeRequestMap, connections, connectedNetworks, loading} = useSelector(state => state.system);

  useEffect(() => {
    dispatch(setAppBar({
      state: APP_BAR_STATE.LABEL,
      label: 'My Network'
    }));
  }, [])

  const openRequests = () => {
    mixpanel.track('Open Requests From Network Page');
    navigate('/app/network/requests');
  }

  const onAlertAction = () => {
    mixpanel.track('Network Page Alert Button');
    navigate('/app/request/topic')
  };

  const getConnectionsRows = () => {
    let list = [];
    if (loading[LOADING_KEYS.CONNECTIONS_LOAD]) {
      list = list.concat(Array.from(new Array(3)).map((_, index)=> <ConnectionRowSkeleton key={index} />));
    } else {
      if (connections.length < 5) {
        list.push (
          <Alert
            severity="info"
            key="info"
            action={
              <Button
                onClick={onAlertAction}
                color="inherit" size="small">
                Start
              </Button>
            }
          >
            Add to your network by having a conversations.
          </Alert>
        );
      }
      list = list.concat(connections.map((connection) => {
        const current_user = connection.User1['id'] == user['id'] ? connection.User2 : connection.User1;
        return (
          <ConnectionRow
            mixpanelReferrer='My Network Page'
            key={current_user['id']}
            user={current_user}
          />
        );
      }));
    }
    return list;
  }

  const openNetworkPage = (network) => {
    mixpanel.track('OpenNetworkPage', {
      'Name': network.Name
    });
    navigate('/app/network/page/'+network.Handle);
  }
  const getNetworksRows = () => {
    let list = [];
    if (loading[LOADING_KEYS.CONNECTIONS_LOAD]) {
      list = list.concat(Array.from(new Array(2)).map((_, index)=> <ConnectionRowSkeleton key={index} />));
    } else {
      list = connectedNetworks.map((network) => {
        return (
          <ListItem
            className={styles.paperListItemParent}
            button
            onClick={()=>openNetworkPage(network)}
            disableGutters
            key={network.Handle}
          >
            <Paper
              className={styles.paperListItem}>
              <ListItemAvatar>
                  <Avatar src={network.Logo} />
              </ListItemAvatar>
              <ListItemText
                primary={network.Name}
                secondary={`${network.count} people`}
              />
              <ChevronRightIcon />
            </Paper>
          </ListItem>
        );
      })
    }
    return list;
  }

  const getRequestsElement = () => {
    const activeRequests = Object.keys(activeRequestMap);
    if (activeRequests.length == 0) return null;
    return (
      <ListItem
        className={styles.paperListItemParent}
        button
        onClick={openRequests}
        disableGutters
      >
        <Paper
          className={styles.paperListItem}>
          <ListItemAvatar>
            <Avatar className={classes.requestsAvatar}>{activeRequests.length}</Avatar>
          </ListItemAvatar>
          <ListItemText primary="Incoming Call Requests" />
          <ChevronRightIcon />
        </Paper>
      </ListItem>

    );

  }

  const getSkeletons = () => new Array(3).map((item, index) => <ConnectionRowSkeleton key={index} />)

  return (
    <Box className={styles.containerPadding}>
      <List>
        {getRequestsElement()}
        <ListSubheader>People</ListSubheader>
        {getConnectionsRows()}
        <ListSubheader>Networks</ListSubheader>
        {getNetworksRows()}
      </List>
    </Box>
  );
}

export default Network;
