import React, {useState, useEffect} from 'react';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button ,
  makeStyles,
  Avatar
} from "@material-ui/core"
import {
  Alert,
} from '@material-ui/lab';
import CancelIcon from '@material-ui/icons/Cancel';
import SharedStyles from '../../../../shared_styles'
import clsx from 'clsx';
import moment from "moment-timezone"
import {navigate} from "@reach/router"
import { createEnum, REQUEST_ORIGIN } from "../../../../../lib/constants"
import Util from "../../../../../lib/util"
import { ElementOrLoader } from "../../../../util"
import {removeActiveRequest} from "../../system/system_slice"
import { useDispatch , useSelector} from "react-redux"
import {setSnackbar} from "../../system/system_slice"

const useStyles = makeStyles( theme => ({
  container: {
    position: 'relative',
    // backgroundColor: 'blue',
    padding: theme.spacing(1)
  },
  fixedWidth: {
    width: '200px',
    height: '255px'
  },
  cancelButton: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    color: theme.palette.grey["400"]
  },
  grid: {
    alignItems: 'center',
  },
  avatar: {
    width: '90px',
    height: '90px',
  },
  acceptButton: {
    fontWeight: 'bold',

  }

}));

const STATE = createEnum(['INITIAL', 'ACCEPTED', 'DECLINED']);

const SmallRequestModal = ({request, confirmFn, cancelFn, openFn, fullWidth, containerClass}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const styles = SharedStyles();
  const widthClass = fullWidth?null:classes.fixedWidth;

  const [time, setTime] = useState('');
  const [date, setDate] = useState('');
  const [label, setLabel] = useState('');
  const [picUrl, setPicUrl] = useState('');
  const [user, setUser] = useState({});
  const [state, setState] = useState(STATE.INITIAL)

  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    let m = moment(request.TimeSlot);
    setDate(m.format('ddd, MMM Do'))
    setTime(`${m.format('h:mma')} - ${m.add(30, 'minutes').format('h:mma')} ${Util.getTimezone()}`);

    const userToShow = request.User?request.User:request.RequestedUser;

    setLabel(Util.getUserFullName(userToShow));
    setUser(userToShow);
    setPicUrl(userToShow.ProfilePic);

  },[request])

  const openRequest = () => {
    if (openFn) {
      return openFn(request);
    }

    switch(state) {
      case STATE.ACCEPTED:
        return navigate(`/app/call/${request.ExternalId}`)
      default:
        return navigate(`/app/request/pending/${request.ExternalId}`)
    }
  }

  const handleCancel = (event) => {
    event.stopPropagation();
    setLoading(true);
    cancelFn(request)
      .then(() => {
        dispatch(setSnackbar({
          open: true,
          text: 'Request declined.'
        }));
        dispatch(removeActiveRequest(request['ExternalId']));
      })
      .catch(()=>setLoading(false));
  }
  const handleConfirm = (event) => {
    event.stopPropagation();
    setLoading(true);
    confirmFn(request)
      .then(() => {
        setState(STATE.ACCEPTED);
        setLoading(false);
      });
  }


  const getDeclined = () => (
    <Box className={styles.centerColumnElements} style={{height: '100%'}}>
      <Util.UserAvatar
        user={user}
        className={classes.avatar}
        style={{marginBottom: '10px'}}
        />
      <Alert severity="error">Call Request Declined</Alert>
    </Box>
  );
  const getAccepted = () => (
    <Box className={styles.centerColumnElements} style={{height: '100%'}}>
      <Util.UserAvatar
        user={user}
        className={classes.avatar}
        style={{marginBottom: '10px'}}
      />
      <Alert severity="success">Call Request Accepted</Alert>

    </Box>
  );

  const getInitial = () => {

    let originChip = null;
    if (request) {
      if (request.Origin == REQUEST_ORIGIN.PLATFORM) {
        originChip = <Typography color="primary">{request.Topic.Label}</Typography>;
      } else {
        originChip = <Typography className={classes.infoText}>Personal Request</Typography>;
      }
    }

    return (
      <React.Fragment>
        <CancelIcon onClick={handleCancel} className={classes.cancelButton}/>
        <Grid direction="column" container className={classes.grid} spacing={1}>
          <Grid item>
            <Util.UserAvatar
              user={user}
              className={classes.avatar}
            />
          </Grid>
          <Grid item className={styles.centerText} style={{ flexGrow: 1 }}>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{label}</Typography>
            {originChip}
            <Typography variant="subtitle2">{time}</Typography>
            <Typography variant="subtitle2">{date}</Typography>
          </Grid>
          {confirmFn &&
            <Grid item className={styles.fullWidth}>
              <Button color="primary" fullWidth onClick={handleConfirm} variant="outlined" className={classes.acceptButton}>
                Accept
              </Button>
            </Grid>
          }
        </Grid>
      </React.Fragment>
    );
  }



  const getBody = () => {
    switch(state){
      case STATE.ACCEPTED:
        return getAccepted();
      case STATE.DECLINED:
        return getDeclined();
      default:
        return getInitial();
    }

  }

  return (
    <Paper variant="outlined" className={clsx([classes.container, widthClass, containerClass])} onClick={openRequest}>
      <ElementOrLoader loading={loading}>
        {getBody()}
      </ElementOrLoader>
    </Paper>
  );
}

export default SmallRequestModal;
