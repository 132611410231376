import React, {useState, useEffect} from 'react';
import {
  Box,
  Chip,
  Paper,
  Grid,
  Typography,
  Button,
  makeStyles,
  Avatar, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction
} from "@material-ui/core"
import {
  Skeleton
} from '@material-ui/lab';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CancelIcon from '@material-ui/icons/Cancel';
import SharedStyles from '../../shared_styles'
import clsx from 'clsx';
import moment from "moment"
import {useSelector, useDispatch} from "react-redux"
import {navigate} from "@reach/router"
import Util from "../../../lib/util"
import { REQUEST_ORIGIN } from "../../../lib/constants"

const useStyles = makeStyles( theme => ({
  container: {
    position: 'relative',
    // backgroundColor: 'blue',
    padding: theme.spacing(1)
  },
  fixedWidth: {
    width: '160px'
  },
  cancelButton: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    color: theme.palette.grey["400"]
  },
  grid: {
    alignItems: 'center',
  },
  avatar: {
    width: '50px',
    height: '50px',
  },
  infoText: {
    color: theme.palette.info.main
  }
}));


const UpcomingCallModal = ({callLog, noShadow}) => {
  const classes = useStyles();
  const styles = SharedStyles();
  const {user} = useSelector(state => state.system);

  const [userPic, setUserPic] = useState('')

  const [name, setName] = useState('');
  const [time, setTime] = useState('');
  const [date, setDate] = useState('');
  const [picUrl, setPicUrl] = useState('');
  const [otherUser, setOtherUser] = useState({});

  useEffect(() => {
    let userKey = callLog.User2['id'] == user['id']? 'User1': 'User2';
    setOtherUser(callLog[userKey]);

    let m = moment(callLog.Request.TimeSlot);
    setDate(m.format('dddd, MMMM Do'))
    setTime(`${m.format('h:mma')} - ${m.add(30, 'minutes').format('h:mma')} ${Util.getTimezone()}`);
  }, []);

  const open = () => {
    navigate(`/app/call/${callLog.ExternalId}`)
  }

  let originChip = null;
  if (callLog.Request.Origin == REQUEST_ORIGIN.PLATFORM) {
    originChip = <Typography color="primary" >{callLog.Request.Topic.Label}</Typography>
  } else {
    originChip = <Typography className={classes.infoText} >Direct Request</Typography>
  }

  return (
    <ListItem
      button
      alignItems="flex-start"
      onClick={open}
      disableGutters>
      <ListItemAvatar>
        <Util.UserAvatar
          className={classes.avatar}
          user={otherUser}
        />
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={Util.getUserFullName(otherUser)}
        secondary={
          <React.Fragment>
            {originChip}
            <Typography>{time}</Typography>
            <Typography>{date}</Typography>
          </React.Fragment>
        }
      />
      <ListItemSecondaryAction>
          <KeyboardArrowRightIcon />
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default UpcomingCallModal;

const UpcomingCallModalSkeleton = () => {
  return <ListItem><Skeleton variant="rect" style={{width: "100%", height:"120px"}} /></ListItem>;
}
export {UpcomingCallModalSkeleton};
