import React, {useState, useEffect, useContext} from 'react';
import {MixpanelContext} from "../../../../lib/tracker"
import clsx from 'clsx';
import { setAppBar, setLoading } from "../system/system_slice"
import { APP_BAR_STATE, USER_TYPE } from "../../../../lib/constants"
import { ElementOrLoader } from "../../../util"
import { useDispatch, useSelector } from "react-redux"
import hasParams from "../../../has_params"
import {
  Box,
  Button,
  Typography,
  makeStyles,
  Chip,
  Paper, ListItem, ListItemIcon, ListItemText,
} from "@material-ui/core"
import SharedStyles from '../../../shared_styles'
import Util from "../../../../lib/util"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import { navigate } from "@reach/router"
import { apiGet } from "../../../../lib/api"
import MessageIcon from "@material-ui/icons/Message"

const LOADING_KEY = 'loadingUserProfile';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: '125px',
    width: '125px'
  },
  name: {
    fontSize: '1.25rem'
  },
  statement: {
    color: theme.palette.grey['600']
  },
  button: {
    margin: theme.spacing(3, 0)
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

const Profile = ({userId}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const styles = SharedStyles();
  const mixpanel = useContext(MixpanelContext);
  const {loading, canonicalTopicMap, networkInfo, user} = useSelector(state => state.system);
  const [profileUser, setProfileUser] = useState({});

  useEffect(() => {
    dispatch(setLoading({key: LOADING_KEY, value: true}));
    apiGet(`user/get-user-profile`, {id: userId})
      .then((data) => {
        if (data.error) {
          return;
        }
        setProfileUser(data.user);
        dispatch(setLoading({key: LOADING_KEY, value: false}));
      })
  }, [])

  useEffect(() => {
    dispatch(setAppBar({
      state: APP_BAR_STATE.BACK_LABEL,
      label: '' // Util.getUserFullName(profileUser),
    }));
  }, [profileUser])

  const isStudent = () => profileUser.Type == USER_TYPE.STUDENT;

  const sendMessage = () => {
    const subject = `[Greenwoodx] New Message From ${Util.getUserFullName(user)}`;
    const body = `\
Hi ${profileUser.FirstName},
`;
    openMailTo(encodeURI(subject), encodeURI(body));
  }

  const openMailTo = (subject, body) => {
    const grnwdxEmail = 'grnwdx@gmail.com';
    let mailTo = `mailto:${profileUser.Email}?cc=${grnwdxEmail}&subject=${subject}&body=${body}`;
    mixpanel.track('Send Message', {
      'Surface': 'Profile page',
      'Type': 'General',
      'From': user.id,
      'To': profileUser.id
    })
    window.open(mailTo, "_blank")
  }

  const scheduleTime = () => {
    mixpanel.track('Send Message', {
      'From': user.id,
      'To': profileUser.id,
      'To is student': isStudent()
    })
    if (isStudent()) {
      return alert("This action isn't supported yet. To vote for it, use the 'Send App Feedback' button in the menu or in settings to let us know");
    }
    return navigate(`/app/network/schedule/${userId}`)
  }

  const getTopicChips = (indexArray) => {
    if (!canonicalTopicMap || !indexArray) return;
    const labelArray =  indexArray.map((topicIndex) => canonicalTopicMap[topicIndex].Label);
    return <span>{labelArray.join(',  ')}</span>;
  }

  const getSendMessageListElement = () => {
    return (
      <ListItem
        key="send_message"
        button
        onClick={sendMessage}>
        <ListItemIcon>
          <MessageIcon />
        </ListItemIcon>
        <ListItemText primary="Send Message" secondary={`Send ${profileUser.FirstName} a message or update.`} />
      </ListItem>
    );
  }


  return (
    <ElementOrLoader loading={loading[LOADING_KEY]} >
      <Box
        className={styles.innerPadding}
      >
        <Paper className={clsx([styles.innerPadding, styles.bottomMargin])}>
        <Box className={clsx([styles.centerColumnElements, styles.centerText])}>
          <Util.UserAvatar user={profileUser} className={classes.avatar}/>
          <Typography className={clsx([styles.boldText, classes.name])}>{Util.getUserFullName(profileUser)}</Typography>
        </Box>
        <Typography className={clsx([styles.centerText, classes.statement])}>{profileUser.Statement}</Typography>
        {profileUser.Type == USER_TYPE.NORMAL &&
          <Button
            className={classes.button}
            onClick={scheduleTime}
            color="primary"
            variant={isStudent() ? "outlined" : "contained"}
            fullWidth
          >
            <Typography>{`Meet with ${profileUser.FirstName}`}</Typography>
            <ChevronRightIcon/>
          </Button>
        }
          {getSendMessageListElement()}
        </Paper>
        {profileUser.Type == USER_TYPE.NORMAL && !networkInfo.SkipTopics &&
          <Paper variant="outlined" className={styles.denseInnerPadding} style={{ marginBottom: '10px' }}>
            <Typography className={styles.boldText} variant="body1" style={{ marginBottom: '10px' }}>
              Known Topics
            </Typography>
            {getTopicChips(profileUser.KnownTopics)}
          </Paper>
        }
        {!networkInfo.SkipTopics &&
          <Paper variant="outlined" className={styles.denseInnerPadding}>
            <Typography className={styles.boldText} variant="body1" style={{ marginBottom: '10px' }}>
              Interests
            </Typography>
            {getTopicChips(profileUser.Interests)}
          </Paper>
        }
      </Box>

    </ElementOrLoader>
  );
}

export default hasParams(Profile);
