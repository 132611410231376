import React, {useState, useEffect, useContext} from 'react';
import {MixpanelContext} from "../../../../lib/tracker"
import clsx from 'clsx';
import {
  Box,
  Avatar,
  Container,
  Grid,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
  ListSubheader, CircularProgress, Button,
} from "@material-ui/core"
import SharedStyles from '../../../shared_styles'
import {setAppBar} from "../system/system_slice"
import { API_ADDRESS, APP_BAR_STATE, LOADING_KEYS, REQUEST_ACTION } from "../../../../lib/constants"
import { useDispatch, useSelector } from "react-redux"
import SmallRequestModal from "../request/ui/small_request_modal"
import { navigate } from "@reach/router"
import { apiGet } from "../../../../lib/api"
import { ElementOrLoader } from "../../../util"
import ConnectionRow, { ConnectionRowSkeleton } from "./ui/connection_row"
import { Alert } from "@material-ui/lab"


const useStyles = makeStyles((theme) => ({
  logo: {
    width: '270px',
    height: '90px',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat'
  }
}))

const NetworkPage = (props) => {
  const dispatch = useDispatch();
  const styles = SharedStyles();
  const classes = useStyles();
  const mixpanel = useContext(MixpanelContext);

  const [loadingNetworkInfo, setLoadingNetworkInfo] = useState(false);
  const [networkInfo, setNetworkInfo] = useState({});
  const [networkMembers, setNetworkMembers] = useState([]);


  useEffect(() => {
    mixpanel.track('Network Page', {
      'Handle':props.handle
    });
  }, [mixpanel]);

  useEffect(() => {
    setLoadingNetworkInfo(true);
    apiGet('network/network-data', {handle: props.handle})
      .then((data) => {

        dispatch(setAppBar({
          state: APP_BAR_STATE.BACK_LABEL,
          label: data.Name
        }));

        setNetworkInfo(data.info);
        setNetworkMembers(data.members);
        setLoadingNetworkInfo(false);
      })
  }, [])

  const getMemberRows = () => {
    let list = [];
    if (loadingNetworkInfo) {
      list = list.concat(Array.from(new Array(3)).map((_, index)=> <ConnectionRowSkeleton key={index} />));
    } else {
      list = networkMembers.map((member) => {
        return (
          <ConnectionRow
            mixpanelReferrer='Network Data Page'
            key={member['id']}
            user={member}
          />
        );
      });
    }
    return list;
  }

  return (
    <Container maxWidth="sm">
      <Grid container spacing={5}>
        <ElementOrLoader loading={loadingNetworkInfo}>
          <Grid item xs={12} className={styles.centerElements} style={{padding:0}}>
            <Box className={classes.logo} style={{backgroundImage:`url('${networkInfo.Logo}')`}} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" className={styles.centerText}>
              {networkInfo.Blurb}
            </Typography>
          </Grid>
        </ElementOrLoader>
        <Grid item xs={12}>
          <List>
            <ListSubheader>Members</ListSubheader>
            {getMemberRows()}
          </List>
        </Grid>
      </Grid>
    </Container>
  );
}

export default NetworkPage;
