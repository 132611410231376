import React, { useState, useEffect, useReducer } from "react"
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
  ListSubheader,
  TextField, CircularProgress,

} from "@material-ui/core"
import SharedStyles from '../../../shared_styles'
import { setAppBar, setLoading, setSnackbar } from "../system/system_slice"
import { API_ADDRESS, APP_BAR_STATE, USER_SETTING_KEY } from "../../../../lib/constants"
import { useDispatch, useSelector } from "react-redux"
import { navigate } from "@reach/router"
import axios from "axios"
import { getAuthToken } from "../../../../lib/auth"
import TopicSelect from "./topic_select"
import Util from "../../../../lib/util"

const SAVE_BUTTON_LOADING_KEY = 'settingsKnownTopicsSaving';

const KnownTopics = () => {
  const dispatch = useDispatch();

  const {user, loading} = useSelector(state => state.system);

  const topicChipReducer = (state, data) => {
    console.log(state, data);
    let new_value = true;
    if (state && state[data['Key']]) {
      const newState = Object.assign({}, state);
      delete newState[data['Key']];
      return newState;
    }
    return {...state, [data['Key']]:new_value};
  }
  const [topicsState, dispatchTopicToggle] = useReducer(
    topicChipReducer,
    Util.arrayToMap(user.KnownTopics));

  useEffect(() => {
    dispatch(setAppBar({
      state: APP_BAR_STATE.BACK_LABEL,
      label: 'Your Knowledge',
      backLocation: '/app/settings'
    }));
  }, [])

  const onChipClick = (topic) => {
    if (Object.keys(topicsState).length == 1 && topicsState[topic['Key']]) {
      alert("You need to have at least one selected.");
      return;
    }
    dispatchTopicToggle(topic);
  }

  const save = () => {
    const data = {
      key: USER_SETTING_KEY.KNOWN_TOPICS,
      value: Object.keys(topicsState)
    };
    dispatch(setLoading({key: SAVE_BUTTON_LOADING_KEY, value: true}));
    const config = {
      headers: { Authorization: `Bearer ${getAuthToken()}` }
    };
    axios.post(`${API_ADDRESS}/user/update`, data, config)
      .then((response) => {
        if (response.data.error) {
          return alert(response.data.error_message)
        }

        dispatch(setSnackbar({
          open: true,
          text: 'Your Knowledge Saved'
        }))
        dispatch(setLoading({key: SAVE_BUTTON_LOADING_KEY, value: false}));
        navigate('/app/settings');
      })
  }

  return (
    <Container maxWidth="sm">
      <TopicSelect
        topicState={topicsState}
        topicKey="knownTopics"
        header="Your Knowledge"
        subheading="Update the topics you know by picking all that apply."
        onChipClick={onChipClick}
        inMedicine={user.InMedicine}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={save}
      >
        {loading[SAVE_BUTTON_LOADING_KEY]? <CircularProgress style={{color:'white', borderColor:'white'}} size={24}/> : 'Save'}
      </Button>
    </Container>
  );
}

export default KnownTopics;
