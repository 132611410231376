import React, {useEffect, useState, useContext} from 'react'
import { Router, navigate } from '@reach/router'
import { MixpanelContext } from '../lib/tracker';
import clsx from 'clsx'

import AuthRoute from '../components/auth_route';
import { getAuthToken, isAuthenticated } from "../lib/auth"
import {
  Box,
  Backdrop,
  CircularProgress, Link, Snackbar,
  ThemeProvider,
  Typography
} from "@material-ui/core"
import theme from "../lib/theme"
import hasParams from "../components/has_params"

import App from '../components/app/app'
import SelectTopic from '../components/app/features/request/select_topic';
import SelectTime from '../components/app/features/request/select_time';
import WaitList from './waitlist';
import UserCalendar from "../components/app/features/request/user_calendar";
import MatchView from "../components/app/features/request/match_view"
import Home from "../components/app/home"
import Network from '../components/app/network'
import Requests from "../components/app/features/network/requests"
import NetworkPage from '../components/app/features/network/network_page'

import Settings from '../components/app/settings'
import Availability from "../components/app/features/settings/availability";
import Interests from "../components/app/features/settings/interests"
import KnownTopics from "../components/app/features/settings/known_topics"
import CallHistory from "../components/app/features/settings/call_history"
import Statement from "../components/app/features/settings/statement";
import LinkedIn from "../components/app/features/settings/linkedin"
import Profile from "../components/app/features/network/profile"


import { Provider, useDispatch, useSelector } from "react-redux"

import { makeStyles } from '@material-ui/core/styles';

import ReduxStore from '../components/app/store';
import PendingRequest from "../components/app/features/request/pending_request"
import axios from "axios"
import { API_ADDRESS, USER_PHASE, LOADING_KEYS } from "../lib/constants"
import Onboard from "../components/app/features/onboard/onboard"
import { setSnackbar } from "../components/app/features/system/system_slice"
import { firstLoad, secondLoad } from "../lib/api"
import SharedStyles from '../components/shared_styles'
import { SnackBarWrap } from "../components/util"

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  logo: {
    width: '300px'
  }
}));


const AppRoute = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const styles = SharedStyles();
  const mixpanel = useContext(MixpanelContext);

  const {loading, user} = useSelector(state => state.system);
  const [waitList, setWaitList] = useState(false);
  const [phase, setPhase] = useState(USER_PHASE.APP);


  useEffect(() => {
    if (!isAuthenticated()) {
      navigate(`/login?url=${props.location.pathname}`, {
        state: {
          snackbarMessage:'You need to be registered to view that page.'
        }
      });
    }


    firstLoad(dispatch)
      .then((data)=>{
        if (data.user.Phase == USER_PHASE.ONBOARD) {
          return navigate('/onboard');
        }
        setPhase(data.user.Phase);
        if (data.user.Phase == USER_PHASE.APP) {
          secondLoad(dispatch);
        }
      })
      .catch((errorCode) => {
        alert(errorCode);
        return navigate('/login');
      });

  }, [])

  useEffect(() => {
    mixpanel.track('Load App');
  }, [mixpanel]);

  useEffect(() => {
    mixpanel.people.set({
      'First Name': user.FirstName,
      'Last Name': user.LastName,
      'Id': user.id
    });
    mixpanel.identify(user.id)
    mixpanel.register_once({
      'First Login Date': new Date().toISOString()
    })

  }, [mixpanel, user])

  if (loading[LOADING_KEYS.FIRST_LOAD]) {
    return (
      <Backdrop className={clsx([classes.backdrop, styles.centerColumnElements])} open>
        <Typography>Connecting to</Typography>
        <Box>
          <svg width="451px" height="101px" viewBox="0 0 451 101" version="1.1" xmlns="http://www.w3.org/2000/svg"  className={classes.logo}>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Group">
                <rect id="Rectangle" fill="#673AB7" x="0" y="0" width="451" height="101"></rect>
                <text id="Greenwoodx" fontFamily="Avenir-Heavy, Avenir" fontSize="65" fontWeight="600" fill="#FFFFFF">
                  <tspan x="28.745" y="69">Greenwoodx</tspan>
                </text>
              </g>
            </g>
          </svg>
        </Box>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  const getRoutes = () => {
    switch(phase) {
      case USER_PHASE.WAITLIST:
        return <AuthRoute component={WaitList} path="/app"/>;
      case USER_PHASE.APP:
        return (
          <AuthRoute {...props} location={props.location} component={App} path="/app">
            <AuthRoute component={Home} path="/"/>
            <AuthRoute component={Settings} path="/settings"/>
            <AuthRoute component={Statement} path="/settings/statement"/>
            {/* LinkedIn component uses props for OAuth2 code */}
            <AuthRoute component={LinkedIn} path="/settings/linkedin" params={props.params}/>
            <AuthRoute component={Availability} path="/settings/availability"/>
            <AuthRoute component={Interests} path="/settings/interests"/>
            <AuthRoute component={KnownTopics} path="/settings/known"/>
            <AuthRoute component={CallHistory} path="/settings/history"/>

            <AuthRoute component={SelectTopic} path="/request/topic"/>
            <AuthRoute component={SelectTime} path="/request/time"/>
            <AuthRoute component={PendingRequest} path="/request/pending/:requestId"/>
            <AuthRoute params={props.params} component={MatchView} inApp path="/call/:requestId"/>

            <AuthRoute component={Network} path="/network"/>
            <AuthRoute component={Requests} path="/network/requests"/>
            <AuthRoute component={Profile} path="/network/profile/:userId"/>
            <AuthRoute component={NetworkPage} path="/network/page/:handle"/>
            <AuthRoute params={props.params} component={UserCalendar} inApp path="/network/schedule/:userId"/>
          </AuthRoute>
        );
    }

  }

  return (
      <Router>
        {getRoutes()}
      </Router>
  );
}

const ThemeReduxProvider = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={ReduxStore}>
        <SnackBarWrap>
          <AppRoute {...props} />
        </SnackBarWrap>
      </Provider>
    </ThemeProvider>
  );
}

export default hasParams(ThemeReduxProvider);
