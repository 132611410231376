import React, { useState, useEffect, useContext } from "react"
import {MixpanelContext} from "../../../../../lib/tracker"
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Paper,
  Grid,
  Chip,
  Divider,
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core"
import {
  Skeleton
} from '@material-ui/lab';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Util from "../../../../../lib/util"
import SharedStyle from '../../../../shared_styles'
import { navigate } from "@reach/router"
import { useSelector } from "react-redux"

const useStyles = makeStyles((theme) => ({
  chip: {
    height: theme.spacing(3)
  },
  avatar: {
    height: '50px',
    width: '50px'
  },
}))

const ConnectionRow = ({user, mixpanelReferrer}) => {
  const classes = useStyles();
  const styles = SharedStyle();
  const mixpanel = useContext(MixpanelContext);

  const {canonicalTopicMap} = useSelector(state => state.system);

  const [picUrl, setPicUrl] = useState('');

  useEffect(()=>{
    setPicUrl(user.ProfilePic);
  },[])

  const getTopics = () => {
    const topicsSummary = [];
    for (let i = 0; i < user.KnownTopics.length && i < 3; i++) {
      const topic = canonicalTopicMap[user.KnownTopics[i]];
      if (topic) {
        topicsSummary.push(topic.Label);
      }
    }
    return topicsSummary.join(', ') + (user.KnownTopics.length > 3?', and more...':'');
  }

  const open = () => {
    mixpanel.track('Connection Row To Profile', {
      'Referrer': mixpanelReferrer
    });
    return navigate(`/app/network/profile/${user['id']}`)
  }

  return (
    <ListItem
      button
      onClick={open}
      alignItems="flex-start"
      className={clsx([classes.container, styles.centerElements])}
      disableGutters>
      <ListItemAvatar>
        <Util.UserAvatar user={user} className={classes.avatar} />
      </ListItemAvatar>
      <ListItemText
        primaryTypographyProps={{className:styles.boldText}}
        primary={Util.getUserFullName(user)}
        secondary={getTopics()}
      />
      <ChevronRightIcon />
    </ListItem>
  );
}

export default ConnectionRow;

const ConnectionRowSkeleton = () => {
  return(
    <ListItem>
      <Skeleton variant="rect" style={{height: '58px', width: '100%'}} />
    </ListItem>
  );

}
export {
  ConnectionRowSkeleton
};
