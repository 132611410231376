import React, {useState, useEffect, useContext} from 'react'
import { MixpanelContext } from '../../../lib/tracker';
import { createEnum, LOADING_KEYS, PURPLE, REQUEST_ORIGIN, REQUEST_STATUS } from "../../../lib/constants"
import {
  Box,
  Button,
  Chip,
  Grid,
  Badge,
  Paper,
  makeStyles,
  withStyles,
  lighten,
  Typography,
  LinearProgress, List,
} from "@material-ui/core"
import { navigate } from "@reach/router"

import {useSelector, useDispatch} from "react-redux"

import SharedStyles from '../../shared_styles'
import { apiCancelRequest, apiPost, apiGet } from "../../../lib/api"
import clsx from 'clsx';
import { setLoading, setSnackbar, setSystemData } from "../features/system/system_slice"
import moment from "moment"
import Util from "../../../lib/util"
import { SmallConnectionModalSkeleton } from "../features/network/ui/small_connection_modal"
import { ElementOrLoader } from "../../util"
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {
  Alert,
} from '@material-ui/lab';
import {
  styles,
} from '@material-ui/lab/Alert';
const STATE = createEnum(['INITIAL', 'FINDING', 'FOUND', 'NOT_FOUND'])

const useStyles = makeStyles(theme=>({
  container: {
    height: '200px',
    marginBottom: theme.spacing(1),
    backgroundColor: PURPLE
  },
  foundContainer: {
    minHeight: '200px',
    marginBottom: theme.spacing(1),
  },
  topicChip: {
    background: 'white',
    color: PURPLE
  },
  findingProfessBar: {

  },
  avatar: {
    width: '100px',
    height: '100px',
  },



  section: {
    marginTop: theme.spacing(5)
  },
  padding: {
    padding:theme.spacing(0, 1)
  },
  horizontalContainerScroll: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display:'none'
    }
  },
  horizontalScroll: {
    display: 'inline-flex',
    paddingRight: theme.spacing(2.5),
    paddingLeft: theme.spacing(1),
  },
  usersAvailableChip: {
    background: lighten(theme.palette.success.main, .9),
    margin: theme.spacing(1,0)
  }

}));

const WhiteLinearProgress = withStyles(theme => ({
  root: {
    height: 5,
    borderRadius: 5,
    width: '100%'
  },
  bar: {
    backgroundColor: 'white'
  }
}))(LinearProgress);

const HomePageHero = ({data}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const styles = SharedStyles();
  const mixpanel = useContext(MixpanelContext);


  const {userRequests, callLogs, user, canonicalTopicMap} = useSelector(state => state.system);


  const [state, setState] = useState(STATE.INITIAL);
  const [matchedUser, setMatchedUser] = useState(null);
  const [userRequest, setUserRequest] = useState(null);
  const [matchInfo, setMatchInfo] = useState({});

  const [suggestionLoading, setSuggestionLoading] = useState(true);
  const [callSuggestions, setCallSuggestions] = useState([]);

  useEffect(() => {

    let startDate = moment().add(2, 'days');
    let endDate = moment(startDate).add(2, 'week');
    const startDateString = startDate.utc().format();
    const endDateString = endDate.utc().format();

    const params = {
      start: startDateString,
      end: endDateString
    };
    apiGet('user/get_call_suggestions', params)
      .then((response) => {
        setCallSuggestions(response.suggestions);
      })
      .finally(()=>setSuggestionLoading(false))
  }, [])

  useEffect(() => {
    userRequests.forEach((request) => {
      if (request.Origin == REQUEST_ORIGIN.PLATFORM &&
        request.Status == REQUEST_STATUS.PENDING) {
        setUserRequest(request);
        setState(STATE.FINDING);
      }
    });
  }, [userRequests]);

  useEffect(() => {
    const fifteenMinuteDelta = moment().subtract(5, 'minutes');
    callLogs.forEach((callLog) => {
      if (callLog.Request.Origin == REQUEST_ORIGIN.PLATFORM &&
        callLog.Request.User.id == user.id &&
        moment(callLog.Request.TimeSlot).isAfter(fifteenMinuteDelta)) {
        setUserRequest(callLog.Request);
        setMatchedUser(callLog.User1.id == user.id?callLog.User2:callLog.User1);
        setState(STATE.FOUND);
      }
    });
  }, [callLogs]);


  const handleCancelRequest = () => {
    apiCancelRequest(dispatch, userRequest.ExternalId).then(() => {
      setState(STATE.INITIAL);
      dispatch(setSnackbar({
        open: true,
        text: 'Your request has been canceled.'
      }))
    })
  }

  const getInitialUpper = () => (
    <React.Fragment>
      <Typography
        className={clsx([styles.centerText, styles.textWhite])}
        variant="h5"
      >
        One Conversation Can Change Your Life
      </Typography>
      <SmallConnectionModalSkeleton />
    </React.Fragment>
  )
  const getInitialLower = () => (
    <Button
      className={styles.textWhite}
      variant="outlined"
      onClick={() => {
        mixpanel.track('New Call Request')
        navigate('/app/request/topic');
      }}
    >
      Meet Someone New
    </Button>
  )


  const getFindingUpper = () => {
    if (state != STATE.FINDING) return;
    return (
      <React.Fragment>
        <Grid item>
          <Typography
            className={clsx([styles.centerText, styles.textWhite])}
            variant="h5"
          >
            Finding you a match
          </Typography>
        </Grid>
        <Grid item>
          <Chip
            className={classes.topicChip}
            label={userRequest.Topic.Label}/>
        </Grid>
      </React.Fragment>
    );
  }

  const getFindingLower = () => (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography variant="subtitle1" className={clsx([styles.textWhite, styles.centerText])}>
          We’ll notify you when we find someone
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          className={styles.textWhite}
          fullWidth
          onClick={handleCancelRequest}
        >
          Cancel
        </Button>
      </Grid>
    </React.Fragment>

  )

  const getFoundUpper = () => (
    <Grid item className={styles.centerColumnElements}>
      <Typography
        className={clsx([styles.centerText])}
        variant="h5"
      >
        <span role="img">🎉</span> Matched <span role="img"> 👏🏿</span>
      </Typography>
      <Util.UserAvatar className={classes.avatar} user={matchedUser}/>
      <Typography
        className={clsx([styles.centerText])}
        variant="h6"
      >
        {Util.getUserFullName(matchedUser)}
      </Typography>
      <Chip
        // className={classes.topicChip}
        variant="outlined"
        color="primary"
        label={userRequest.Topic.Label}/>
    </Grid>
  )

  const getFoundLower = () => (
    <Grid item xs={12}>
      <Button
        style={{marginTop:'10px'}}
        color="primary"
        variant="contained"
        fullWidth
        onClick={() => { navigate(`/app/call/${userRequest.ExternalId}`) }}
      >
        View Details
      </Button>
    </Grid>
  )



  const getUpper = () => {
    switch(state) {
      case STATE.INITIAL:
        return getInitialUpper();
      case STATE.FINDING:
        return getFindingUpper();
      case STATE.FOUND:
        return getFoundUpper();
    }
  }

  const getLower = () => {
    switch(state) {
      case STATE.INITIAL:
        return getInitialLower()
      case STATE.FINDING:
        return getFindingLower();
      case STATE.FOUND:
        return getFoundLower();
    }
  }

  const acceptSuggestedCall = (suggestion) => {
    let addedNote = '';
    if (window.confirm('Do you want to talk about something specific?')) {
      addedNote = window.prompt('What do you want to talk about?');
    }
    if (!window.confirm('Important! When you hit OK Greenwoodx finds a REAL person for you to talk to at the given time.')) {
      return alert('Request cancelled.');
    }
    setSuggestionLoading(true);
    // Taken from select_time.js

    const data = {
      timeslot: moment(suggestion[1]).format('Y-MM-DDThh:mm:ss')+'Z',
      topic: suggestion[0],
      note: addedNote
    };

    mixpanel.track('Suggested Call Clicked', {
      'Topic': suggestion[0],
      'Timeslot': moment(suggestion[1]).format('YYYY-MM-DDThh:mm:ss'),
      'Added Note': addedNote && addedNote.length != 0
    });

    apiPost('request/send-topic-request', data)
      .then((response)=> {
        if (response.error) {
          alert(response.error_message);
          return;
        }

        dispatch(setSystemData({key: 'userRequests', value: response.user_requests}));
        dispatch(setSnackbar({
          open: true,
          text: 'Your request has been sent 🎉'
        }))
        setSuggestionLoading(false);
        return navigate('/app');

      })
      .catch(()=>{
        alert('There was a problem reaching the server. Try again please.');
        setSuggestionLoading(false);
      })

  }

  const SuggestedElement = ({data}) => {
    let m = moment(data[1]);
    const date = (m.format('ddd, MMM Do'))
    const time = (`${m.format('h:mma')} to ${m.add(30, 'minutes').format('h:mma')} ${Util.getTimezone()}`);

    const labelLength = canonicalTopicMap[data[0]].Label.length;
    return (
      <Paper className={clsx([styles.innerPadding, styles.centerText])} style={{width: '230px', marginRight: '20px'}}>
        <Typography variant="caption">Meet About</Typography>
        <Box style={{height: '60px'}} className={styles.centerElements}>
          <Typography variant="h5" style={{
            fontWeight: 'bold' ,
            // To normalize the size subtract .01 rem from ever character over a length of 25.
            fontSize: `${1.5 - (.03 * (labelLength>25?labelLength-25:0))}rem`
          }}>{canonicalTopicMap[data[0]].Label}</Typography>
        </Box>
        <Typography variant="subtitle2">{date}</Typography>
        <Typography variant="subtitle1" style={{color:'gray'}}>{time}</Typography>
        <Chip
          className={classes.usersAvailableChip}
          label={<span><span role="img" style={{marginRight: '5px'}}>👋🏿</span> {data[2]} people available</span>} />
        <Button
          onClick={()=>acceptSuggestedCall(data)}
          color="primary"
          variant="outlined"
          style={{marginBottom: '10px'}}
        >This time works</Button>

        <Button
          onClick={()=> {
            mixpanel.track('Suggested Conversation - Pick', {
              'Topic': data[0]
            });
            navigate('/app/request/topic')
          }}
          color="primary"
          size="small"
        >Pick a new time</Button>
      </Paper>

    )
  }


  const getSuggestions = () => {
    if (!canonicalTopicMap) return null;


    return (
      <Box className={classes.section}>
        <Box className={classes.padding}>
          <Typography variant="body1" className={styles.boldText}>
            Suggested Conversations
          </Typography>
        </Box>
          <Box className={classes.horizontalContainerScroll}>
            <Box className={classes.horizontalScroll}>
              <Paper
                onClick={()=>navigate('/app/request/topic')}
                className={clsx([styles.innerPadding, styles.centerText])} style={{width: '230px', marginRight: '20px', backgroundColor: PURPLE}}>
                <Typography variant="h5" style={{ fontWeight: 'bold', color:'white', textAlign:'center', fontSize: '1.25rem' }}>#OneConversation</Typography>
                <Typography variant="body1" style={{color:'white'}}>Get a relevant 1:1 introduction</Typography>
                <Box style={{margin: '20px 0'}}>
                  <Chip
                    className={clsx([classes.topicChip, '.MuiAlert-standardSuccess'])}
                    variant="default"
                    label="Get Started"/>
                </Box>

                <Typography variant="caption" style={{color:'white'}}>Use our AI to expand your network in 2-steps.</Typography>
              </Paper>
              <ElementOrLoader loading={suggestionLoading}>
                {
                  callSuggestions.map((suggestion, index) =>
                    <SuggestedElement key={index} data={suggestion} />
                  )
                }
              </ElementOrLoader>
            </Box>
          </Box>
      </Box>
    );
  }

  if (state == STATE.INITIAL) {
    return getSuggestions();
  }
  return (
    <Paper elevation={1}  className={clsx([
      state == STATE.FOUND?classes.foundContainer:classes.container
      , styles.innerPadding])}>
      <Grid container direction="column" style={{height:'100%'}}>
        <Grid
          container
          item
          style={{
            height: '66.66%'
          }}
          alignItems='center'
          justify='center'
          direction="column"
          spacing={1}
        >
          {getUpper()}
        </Grid>
        <Grid
          container
          item
          style={{
            height: '33.33%'
          }}
          className={styles.centerElements}
        >
          {getLower()}
        </Grid>
      </Grid>
    </Paper>

  );
}

export default HomePageHero;

