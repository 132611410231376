import React, {useState, useEffect} from 'react';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button ,
  makeStyles,
  Avatar
} from "@material-ui/core"
import CancelIcon from '@material-ui/icons/Cancel';
import SharedStyles from '../../shared_styles'
import clsx from 'clsx';
import moment from "moment"

const useStyles = makeStyles( theme => ({
  container: {
    position: 'relative',
    // backgroundColor: 'blue',
    padding: theme.spacing(1)
  },
  fixedWidth: {
    width: '160px'
  },
  cancelButton: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    color: theme.palette.grey["400"]
  },
  grid: {
    alignItems: 'center',
  },
  avatar: {
    width: '76px',
    height: '76px',
  },
}));

const SmallViewAllModal = ({count, viewAllFn, fullWidth}) => {
  const classes = useStyles();
  const styles = SharedStyles();
  const widthClass = fullWidth?null:classes.fixedWidth;

  return (
    <Paper elevation={3} className={clsx([classes.container, widthClass, styles.centerElements])}>
      <Grid direction="column" container className={classes.grid} spacing={1}>
        <Grid item>
          <Avatar
            className={classes.avatar}
          >{count}</Avatar>
        </Grid>
        <Grid item className={styles.fullWidth}>
          <Button color="primary" fullWidth onClick={viewAllFn}>
            View All
          </Button>
        </Grid>
      </Grid>

    </Paper>
  );
}

export default SmallViewAllModal;
